
import Marquee from "react-fast-marquee";
import './ResultMoving.css'

const ResultMoving = () => {
    return (
        <Marquee>
            <p> <span className="mx-2">★★★</span> Hermann Gmeiner School, Bhimtal - Class X Results 2022-23 (100% Pass) ● Samayak Gaur (97%) ● Somya Upreti (97%) ● Divyanshi (96%) ● Mahi (96%) <span className="mx-2">★★★</span> </p>
            <p> Hermann Gmeiner School, Bhimtal - Class Xll Results 2022-23 (100% Pass) ● Kshitiz Singh (95.4%) ● Kanchan Joshi (94%) ● Diya Nayal (94%) ● Mayank Pandey (93%)
            </p>
        </Marquee>
    )
}
export default ResultMoving