import React from "react";

import "./Footer.css";

import footer_logo from "../../image/footer-logo.jpg";
import SummerVacationAssignment from "../../pdf/Summer Vacation Assignment.pdf";
import { NavLink } from "react-router-dom";
import { useInView } from "react-intersection-observer";

function Footer() {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 100,
  });

  return (
    <div className={`footer_main ${inView ? "fade-up" : null}`} ref={ref}>
      <div className=" footer-logo">
        <NavLink to="/">
          <img src={footer_logo} className="footer_logo" />
        </NavLink>
      </div>
      <div className="flex-1">
        <div className="f-2 f-400 footer"> QUICK LINKS</div>
        <div className="footer-section">
          <div className="footer-section-1">
            <NavLink to={"/vision-and-mission"}>
              <div className="f-1-2 fs-6 hover cursor f-500 mt-2 fs-small">
                About Hermann Gmeiner Educational Institutions
              </div>
            </NavLink>
            <NavLink to={"/nep"}>
              <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                Academics & Curriculum
              </div>
            </NavLink>
          </div>
          <div className="footer-section-2">
            <NavLink to={"/house-clubs"}>
              <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                Beyond Curriculum
              </div>
            </NavLink>
            <NavLink to={"/news"}>
              <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                News & Media Coverage
              </div>
            </NavLink>
          </div>
          <div className="footer-section-3">
            <NavLink to={"/infrastructure"}>
              <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                {" "}
                Infrastructure & Facilities
              </div>
            </NavLink>
            <a target="_blank" href={SummerVacationAssignment} />
            <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
              {" "}
              Summer Vacation Assignment
            </div>
          </div>
        </div>
        {/* <div className="footer-section-3">
            <a
              target="_blank"
              href={`https://drive.google.com/drive/folders/1kn3Vee2FTlTqQXhq7C3GxG6D759PmxCr?usp=share_link`}
            >
              <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                {" "}
                Question Bank For Class X (Winter Vacation Home Assignment )
              </div>
            </a>
            <a
              target="_blank"
              href={
                "https://drive.google.com/drive/folders/1sVM6qRFOXe1LAjpu-dbuL35z84Cku5c9?usp=share_link"
              }
            >
              <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
                {" "}
                Question Bank For Class XII (Winter Vacation Home Assignment )
              </div>
            </a>
          </div> */}
        <div className="footer-section-3">
          <a
            target="_blank"
            href={`https://drive.google.com/drive/folders/1PqzhWOOZxz_OIzhkxcSpRzjbwq3SGYiA?usp=share_link`}
          >
            <div className="f-1-2 fs-6 hover cursor f-500 mt-2"> Tender</div>
          </a>
          <NavLink to={"/contact-us"}>
            <div className="f-1-2 fs-6 hover cursor f-500 mt-2">
              {" "}
              Contact Us
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Footer;
