import React, { useState } from "react";
import Footer from "../Footer/Footer";
import GetInTouch from "../GetInTouch/GetInTouch";
import Inspired from "../Inspired/Inspired";
import Landing from "../Landing/Landing";
import MenuSection from "../MenuSection/MenuSection";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import PhotoGallery from "../PhotoGallery/PhotoGaller";
import VideoGallery from "../VideoGallery/VideoGallery";

//
import "./Home.css";
import HomeModal from "../HomeModal/HomeModal";
import ResultMoving from "../ResultMoving/ResultMoving";
function Home() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isShow, steIsShow] = useState(false);
    const [isModal, setIsModal] = useState(true);
    const [showVideo , setShowVideo] = useState(true)
    const handleScroll = (e) => {
        if (e.target.scrollTop > 0 && !isScrolled) {
            setIsScrolled(true);
        }
        if (e.target.scrollTop === 0) {
            setIsScrolled(false);
        }
    };
    const handleClick = () => {
        steIsShow(!isShow);
    };

    const handleClose = () => {
        setIsModal(false);
    };
    return (
        <div className="home_main" onScroll={handleScroll}>
            <Navbar isScrolled={isScrolled} handleClick={handleClick}/>
            <NavbarMenu isShow={isShow} handleClick={handleClick} />
            <Landing video={true} />
            <ResultMoving />
            <div>
                <p
                    className="school_name"
                    style={{
                        color: "#0076bd",
                        textAlign: "center",
                        marginBottom: "0rem",
                    }}
                >
                    Hermann Gmeiner School, Bhimtal
                </p>
            </div>
            <p
                className="school_name1"
                style={{ color: "#0076bd", textAlign: "center" }}
            >
                (Day-cum-Boarding)
            </p>
            <MenuSection />
            <Inspired
                heading="Life In Boarding House"
                desc=""
                btnText="Know More"
                btnNav="boarding"
                headingColor="white"
            />
            <Inspired
                heading="Illustrious Alumni"
                desc="Get inspired by the success stories of our notable alumni!"
                btnText="Read More"
                btnNav="inspired"
                headingColor=""
            />
            <GetInTouch />
            <PhotoGallery />
            <VideoGallery />
            <Footer />
            <HomeModal show={isModal} onHide={handleClose} />
        </div>
    );
}

export default Home;
