import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import b from "../../image/parents/b.png";
import NavaneethPNair from "../../image/students/Navaneeth P Nair.jpg";
import TariniSharma from "../../image/students/Tarini Sharma.jpg";
import LtColShreemKapil from "../../image/Successstories/Lt. Col. Shreem Kapil.jpg";
import MonikaYadav from "../../image/Successstories/Monika Yadav.jpg";
import c from "../../image/parents/c.jpg";
import d from "../../image/parents/d.png";
import e from "../../image/parents/e.png";
import f from "../../image/parents/f.jpg";
import s1 from "../../image/students/s1.jpg";
import s2 from "../../image/students/s2.jpg";
import s3 from "../../image/students/s3.jpg";
import paTopIcon from "../../image/newImg/pa.png";
import "./Content.css";

function Parents(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          {/* parents' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
              alt=""
            />
            Parents' Voice
          </div>
          <div className={`parents_voice`} style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={b}
                style={{
                  width: "10rem",
                  height: "10rem",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                  border: "3px solid #1FDC00F5",
                }}
                alt=""
              />
            </div>
            <div className={`voice_text`}>
              <p style={{ marginTop: "1rem" }}>
                <b>Navaneeth.P. Nair (XII)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I, Wing Commander M Premkumar, father of Navaneeth.P. Nair of
                class 12 , is proud to state that my son is just completing his
                schooling from Herman Gmeiner School Bhimtal. Their is no doubt
                that the school have excellent staff who are able to create
                motivating environment for the students of this school thereby
                enabling them to be self reliant and keep them motivated towards
                achieving the goal they dream .Further their is no doubt the
                school has nurtured the potential and talent of my son as I have
                seen him developing into a confident and capable child. I am
                extremely happy with his performance and my choice . I am
                grateful to all the teachers, staff and a special thanks to
                Principal Mrs. Mahima Bhatt under whose guidance and leadership the
                school excelled .
              </p>
            </div>
          </div>
          <div className="parents_voicee  ">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b>Harshit Akolia (IV) & Parth Akolia (I)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                When we decided to move from India’s Capital to Bhowali
                (Uttarakhand) being a parent of two children the major concern
                was their education. During my nearly three-month quest for the
                finest alternative, Hermann Gmeiner school Bhimtal appeared to
                meet all of my requirements. And when I first came to school, I
                discovered I wasn't mistaken. The lush green aura of the school,
                which spans 42 acres, is quite amazing, and my children are
                receiving a wonderful and true education. This not only improves
                my child's IQ but also improves his emotional intelligence.
                Asides from core education, the school administration places a
                strong emphasis on sports and extracurricular activities. The
                personal connection I've had with the teacher and management
                team since day one is unrivalled.
              </p>
            </div>
            <div className="parentimage" style={{
              marginTop: "2rem"
            }}>
              <img
                src={c}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <div className="parentimage">
              <img
                src={d}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Sehej (X) & Sajag (IV)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I Anuradha, mother of two kids sehej "unmukt" class 10th and
                sajag "alive" class 4th, studies in H.G.S bhimtal. We recently
                shifted from Delhi to bhimtal. I was very doubtful about the
                school to choose, as I felt I won't find school like delhi here
                in bhimtal but I am delighted to share that H.G.S bhimtal proved
                me wrong. We are very much satisfied to get my kids's admission
                here. The huge school campus,lots of sports facilities,number of
                labs ,robotics and most importantly such a humble and
                cooperative principal sir and staff. I specially want to mention
                here that I have never found such a patient, gentle, cooperative
                and approachable principal ever. We as a parent many a times
                want to share our methodologies, concern and issues which we can
                easily do without any hesitation,as the principal sir and staff
                welcome our opinion whole heartedly. I am thankful that my kids
                are part of H.G.S family, which assures holistic development of
                our children. I can write many more about the school but I guess
                I have a very less space to share.
              </p>
            </div>
          </div>
          {/*  */}
          {/* students' voice */}
          <div className={`f-2 f-500 mt-5`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{
                width: "4rem",
                marginRight: "1rem",
              }}

              alt=""
            />
            Students' Voice
          </div>
          {/* <div style={{ marginTop: "1.5rem" }}>
            <img
              src={s1}
              style={{
                width: "10rem",
                height: "10rem",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                border: "3px solid #1FDC00F5",
              }}

              alt=""
            />
            <div style={{ marginLeft: "12rem", marginTop: "-11rem" }}>
              <p style={{ marginTop: "1rem" }}>
                <b>Prashant Singh</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am cadet Prashant Singh of 7 UP NAVAL UNIT NCC BHU
                I am really grateful to  my school for giving me the chance to join the NCC  A Certificate.
                Now I am selected for B certificate from BHU while continuing my studies at Hermann Gmeiner School in class 11. My honorable principal sir (Dr. A.P Gaur) and respected ANO sir (MR. YOGESH KUMAR SINGH) will be remembered by me for supporting me to fulfill my dream.              </p>
            </div>
          </div> */}
          <div className={`parents_voice`} style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={NavaneethPNair}
                style={{
                  width: "10rem",
                  height: "10rem",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                  border: "3px solid #1FDC00F5",
                }}
                alt=""
              />
            </div>
            <div className={`voice_text`}>
              <p style={{ marginTop: "1rem" }}>
                <b>Navaneeth P Nair</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am grateful to my Principal Ma'am Mrs. Mahima Bhatt all the teachers and staff who have guided me throughout my journey.<br />
                One of the best parts about the school is how supportive the teachers are, they are always there for you during the thick and thin. But what pushed me towards doing my best is the competitive nature that is inculcated in the students which I feel is the best environment you can provide for a student.<br />
                I am proud that I completed my schooling at Hermann Gmeiner School Bhimtal and I cherish the value that Hermann Gmeiner School Bhimtal stands for and inculcates in its students.<br />

              </p>
            </div>
          </div>
          {/* <div className="parents_voicee">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b>Harshit Akolia (IV) & Parth Akolia (I)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I joined Hermann Gmeiner School in the summer of 2021 as a student of class XII ‘Humanities’ and thus I spent just one year in the school.
                ‘Like it is said that moments are temporary but memories are permanent’- the days that I spent in HGS were no less than a boon. I absolutely enjoyed every single experience while in school, my teachers made the learning experience valuable and fun. Hermann Gmeiner School, Bhimtal unlike any other school not only works on the academic growth of the students, it also focuses on their overall holistic development.
                Due to these enriching values of the school, I was able to secure 96.6% in my class XII Boards and 99.79% in my Central University Entrance Examination (CUET) held this year for the first time.
                I wish to express my gratitude to the Respected Principal Mr. K.D. Singh sir and all the teachers as this result wouldn’t be possible without their motivation and support. My teachers not only supported me but also helped me clear my doubts and ambiguity whenever I needed it.
                I also want to thank my parents as they not only molded my personality but also helped me streamline my vision for the future. At last, I would like to say that these results were only possible due to the dedication of my teachers, the motivation of the Principal sir, and foremost the support of my parents.
                Very soon I move ahead to a new assignment in my life as I intend to do graduation from Delhi University but I shall always adore the time I spent in the school and respect the values that the school inculcates in its students.
                I hope to be a part of the HGS family in the future as well.

              </p>
            </div>
            <div className="parentimage">
              <img
                src={c}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div> */}
          <div className="parents_voicee ">
            <div className="voicetextt">
              <p style={{ marginTop: "1rem" }}>
                <b>Tarini Sharma</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I joined Hermann Gmeiner School in the summer of 2021 as a student of class XII ‘Humanities’ and thus I spent just one year in the school.<br />
                ‘Like it is said that moments are temporary but memories are permanent’- the days that I spent in HGS were no less than a boon. I absolutely enjoyed every single experience while in school, my teachers made the learning experience valuable and fun. Hermann Gmeiner School, Bhimtal unlike any other school not only works on the academic growth of the students, it also focuses on their overall holistic development.<br />
                Due to these enriching values of the school, I was able to secure 96.6% in my class XII Boards and 99.79% in my Central University Entrance Examination (CUET) held this year for the first time.<br />
                I wish to express my gratitude to the Respected Principal Mr. K.D. Singh sir and all the teachers as this result wouldn’t be possible without their motivation and support. My teachers not only supported me but also helped me clear my doubts and ambiguity whenever I needed it.<br />
                I also want to thank my parents as they not only molded my personality but also helped me streamline my vision for the future. At last, I would like to say that these results were only possible due to the dedication of my teachers, the motivation of the Principal sir, and foremost the support of my parents.<br />
                Very soon I move ahead to a new assignment in my life as I intend to do graduation from Delhi University but I shall always adore the time I spent in the school and respect the values that the school inculcates in its students.
                I hope to be a part of the HGS family in the future as well.

              </p>
            </div>
            <div className="parentimage" style={{
              marginTop: "2rem"
            }}>
              <img
                src={TariniSharma}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div>
          {/* <div style={{ marginTop: "1.5rem", display: "flex" }}>
            <div>
              <p style={{ marginTop: "1rem" }}>
                <b>Shivangi Mishra (X C) </b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                My name is Shivangi Mishra. I am studying in  Hermann Gmeiner School, Varanasi
                since 2013. Now, I am in class 10 C. I am very happy to say that it is a big opportunity for me to become a part of  Hermann Gmeiner school because it provides us a platform where students get to know their qualities and can discover their hidden talent.Principal sir is our inspiration and teachers are sculptures
                who give shape to students. In my opinion, my school is the best.
              </p>
            </div>
            <img
              src={s2}
              style={{
                width: "10rem",
                height: "10rem",
                border: "3px solid #1FDC00F5",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
              }}

              alt=""
            />
          </div> */}
          <div className={`f-2 f-500 mt-5`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{
                width: "4rem",
                marginRight: "1rem",
              }}

              alt=""
            />
            Success Stories
          </div>
          <div className={`parents_voice`} style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={LtColShreemKapil}
                style={{
                  width: "10rem",
                  height: "10rem",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                  border: "3px solid #1FDC00F5",
                }}
                alt=""
              />
            </div>
            <div className={`voice_text`}>
              <p style={{ marginTop: "1rem" }}>
                <b>Lt. Col. Shreem Kapil - Indian Army</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                Shreem Kapil was born and brought up in Bowali, a small town in Uttrakhand. I belonged to a middle-class family but my parents always encouraged me to do well in life. Initially, I studied in a Local school at Bowali and in 1996 I took admission to Hermann Gmeiner School, one of the most reputed Schools in Bhimtal. Hermann Gmeiner School has played a vital role in shaping my life. The school has always been providing the best personality development education to the students. The teachers helped me to enhance my personality. I think one's personality is equally important as one's education.<br />
                Elevated personality never pushes you back, instead, it helps you to move forward and outshine in the crowd. But for having a good personality, being educated is important. This is what Hermann Gmeiner school Bhimtal is doing, i.e providing quality education and imbibing moral values. My school has played an important role in moulding my life by facilitating all-round development. I cleared my CDS Exam in the year 2004 and joined the Indian Military Academy. After undergoing strenuous training at the academy, I passed out as Lt. and joined Gorkha Rifles, one of the most coveted regiments of the Indian Army. I wish good luck to all the Students, Teachers, and Management of Hermann Gmeiner school, Bhimtal.

              </p>
            </div>
          </div>
          <div className="parents_voicee  ">
            <div className="voicetextt">
              <p style={{ marginTop: "1rem" }}>
                <b> Monika Yadav - Deputy Superintendent Of Police, UP </b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                Ms. Monika Yadav, Deputy Superintendent Of Police, UP sees how Hermann Gmeiner School has impacted her life and why she stays connected. In the story of one's life, how and where we end depends a great deal on how and where we begin. And since life can only be understood backward, it is such a happy feeling to look back at the old school days and be transported to what was (and still remains) a utopian world for all of us at the Hermann Gmeiner school.<br />
                The life lived there, the experiences from the soil of that beautiful kingdom, and the learnings we had from the wonderful teachers who were such support even in later life, has set an unbeatable standard to strive for in our lives, even today. We all go through our struggles, but the approach acquired towards life's challenges and the overall personality that is shaped up in the school days is hard to change in any academic and professional institution.<br />
                The whole ambiance of that place is so positive and healthy. The relationships and the friendships forged there have stood the tests of time and have come out stronger after passing out from school. In fact I remember how my friends and teachers, just like my family, stood by me in my struggle phase and cheered for me whenever I accomplished any feat in my later academic and professional life. That was Hermann Gmeiner school! After completing my schooling, I did my B.Tech in Mechanical Engineering from GB Pant University. <br />Thereafter, worked in Mahindra & Mahindra Auto Sector as Assistant Manager and then in PNB as Assistant Manager before qualifying as Deputy Superintendent of Police in the state of Uttar Pradesh.<br />
                In the services I am today, we go through innumerable and detailed interrogations. The whole life of a person (victim, accused, family members etc) is laid open in front of us. When we draw a chart of what leads to what, I thank God for giving me such a healthy and happy childhood, such supportive parents, good schooling, great teachers and wonderful friends, so as to forge a positive and constructive outlook towards life. Though it was written in a different context, but, in free times, whenever I listen to or read "Gar Firdaus bar-rue zamin ast, hami asto, hamin asto, hamin ast" (If there is a heaven on earth, it’s here, it’s here..), I am invariably reminded of Bhimtal and Hermann Gmeiner school! Pure Serenity to even think of the school days !
              </p>
            </div>
            <div className="parentimage" style={{
              marginTop: "2rem"
            }}>
              <img
                src={MonikaYadav}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Parents;
