import pre_primary from "./image/pre-primary.jpg";
import primary from "./image/primary.jpg";
import secondary from "./image/secondary.jpg";
import sr_secondary from "./image/sr_secondary.jpg";

//alumni
import abhishek from "./image/abhishek.jpg";
import prakriti from "./image/prakriti.jpg";
import karandev from "./image/karandev.jpg";
import rohan from "./image/rohan.jpg";
import Rohit from "./image/Rohit.jpg";
import anjan from "./image/anjan.jpg";
import subham from "./image/subham.jpg";
import vivek from "./image/vivek.jpg";
import namrata from "./image/namrata.jpg";
import rakesh from "./image/rakesh.jpg";
import shwetank from "./image/shwetank.jpg";
import juli from "./image/juli.jpg";
import christmas from "./image/christmas.jpg";
import republic from "./image/republic.jpg";
import WorldEarthDay from "./image/World Earth Day.jpg";
import SpicMacayMusical2023 from "./image/Spic Macay Musical 2023.jpg";
import UniversityConnectCareerFair from "./image/University Connect Career Fair.jpg";
import gmeinerDeathAnniversary from "./image/Dr Hermann Gmeiner Death Anniversary.jpg";
import mothersDay from "./image/Mothers Day.jpg";
import DiscoveringHappiness from "./image/DiscoveringHappiness.jpg";

//
import JinsiPhoto from "./image/newImg/Jinsi-picture.png";
import SumantaPhoto from "./image/newImg/Sumanta.jpg";
import DebarotiPhoto from "./image/debaroti.png";
import subrata from "./image/subrata.jpg";

import slider_1 from "./image/slider_1.jpg";
import computer_lab from "./image/computer_lab.jpg";
import classroom from "./image/classroom.jpg";
import trippara2 from "./image/trippara2.jpeg";
import trippara3 from "./image/newImg/trip3.jpeg";

//
import result_10 from "./image/result-10.jpg";
import result_10_1 from "./image/result-10-1.jpg";
import result_10_2 from "./image/result-10-2.jpg";
import result_10_3 from "./image/result-10-3.jpg";
import result_12_1 from "./image/result-12-1.jpg";
import result_12_2 from "./image/result-12-2.jpg";
import result_12_3 from "./image/result-12-3.jpg";

//event

import road_safety from "./image/road_safety.jpg";
import watermelon from "./image/watermelon.jpg";
import awareness from "./image/awareness.jpg";
import charcha from "./image/charcha.jpg";
import brambedkar from "./image/brambedkar.jpg";
//
import laboratories from "./image/laboratories.jpg";
import library from "./image/library.jpg";
import tablet_class from "./image/tablet_class.jpg";
import school_canteen from "./image/school_canteen.jpg";

import transportation from "./image/transportation.jpg";
//
import life_skill_1 from "./image/life_skill_1.jpg";
import life_skill_2 from "./image/life_skill_2.jpg";
import vocational_1 from "./image/vocational_1.jpg";
import vocational_2 from "./image/vocational_2.jpg";
import skill_based from "./image/skill_based.jpg";
import skill_based_2 from "./image/skill_based_2.jpg";
import honor from "./image/honor.jpg";
import hindi from "./image/hindi.jpg";
import diwali from "./image/diwali.jpg";
import unityDay from "./image/unityDay.jpg";
import abacus from "./image/abacus.jpg";
import svp from "./image/svp.jpg";
import ScienceExhabition from "./image/ScienceExhabition.jpg";
import baaldiwas from "./image/veer.jpg";
import spicMiccay from "./image/spicMiccay.jpg";

import ptm from "./image/ptm.jpg";
import childrensDay from "./image/childrensDay.jpg";
import teacherDay from "./image/teacherDay.jpg";
import geography from "./image/geography.jpg";
import gandhi from "./image/gandhi.jpg";
import annual_1 from "./image/annual_1.jpg";
import annual_2 from "./image/annual_2.jpg";
import annual_3 from "./image/annual_3.jpg";
import sports_club from "./image/sports_club.jpg";
import environment_club from "./image/environment_club.jpg";
import expression_club from "./image/expression_club.jpg";

import student_council_2 from "../src/image/studentcouncil.jpg";
import student_council_3 from "./image/student_council_3.jpg";
import ncc_1 from "./image/ncc_1.jpg";
import school_band_1 from "./image/school_band_1.jpg";
import scouts_1 from "./image/scouts_1.jpg";
import bus_1 from "./image/bus_1.jpg";
import award_1 from "./image/award/award_1.jpg";
import award_2 from "./image/award/award_2.jpg";
import award_3 from "./image/award/award_3.jpg";
import award_4 from "./image/award/award_4.jpg";
import award_5 from "./image/award/award_5.jpg";
import award_6 from "./image/award/award_6.jpg";
// import award_7 from "./image/award_7.jpg";
// import award_8 from "./image/award_8.jpg";
// import award_9 from "./image/award_9.jpg";
// import award_10 from "./image/award_10.jpg";
import pre_primary_1 from "./image/pre_primary_1.jpg";
import pre_primary_2 from "./image/pre_primary_2.jpg";
import primary_1 from "./image/primary_1.jpg";
import secondary_1 from "./image/secondary_1.jpg";
import secondary_2 from "./image/secondary_2.jpg";
import secondary2 from "./image/secondary2.jpg";
import sr_secondary_1_1 from "./image/sr_secondary_1_1.jpg";
import sr_secondary_2 from "./image/sr_secondary_2.jpg";
import career_1 from "./image/career_1.jpg";
import career_2 from "./image/career_2.jpg";
import career_4 from "./image/career_4.jpg";
import career_5 from "./image/career_5.jpg";
import sports_1 from "./image/sports_1.jpg";
import sports_3 from "./image/sports_3.jpg";
import arts_club from "./image/arts_club.jpg";
import playground_1 from "./image/playground_1.jpg";
import vision from "./image/vision.jpg";
import mission from "./image/mission.jpg";
import anti_bullying from "./image/anti_bullying.jpg";
import health_1 from "./image/health_club.jpg";
import health_2 from "./image/health_2.jpg";
import value_club from "./image/value_club.jpg";
import social_impact from "./image/social_impact_club.jpg";
import gender_sensitivity from "./image/gender_sensitivity.jpg";
import english_club from "./image/english_club.jpg";
import budding_1 from "./image/budding_1.jpg";
import budding_2 from "./image/budding_2.jpg";
import sdmc from "./image/sdmc.jpg";
import sdmc_2 from "./image/sdmc_2.jpg";
import house_system from "./image/house_system.jpg";
import trip_1 from "./image/trip_1.jpg";
import trip_cover from "./image/trip_cover.jpg";
import trip_cover_1 from "./image/trip_cover_1.jpg";
import trip_4 from "./image/trip_4.jpg";
import trips_1 from "./image/trips_1.jpg";
import primary_curriculum from "./image/primary_curriculum.jpg";
import interactive from "./image/interactive.jpg";
import happy_classroom from "./image/happy_classroom.jpg";
import experimental_learning from "./image/experimental_learning.jpg";
import spacious_classroom from "./image/spacious_classroom.jpg";
import spacious_classroom_2 from "./image/spacious_classroom_2.jpg";
import stem_lab from "./image/stem_lab.jpg";
import stem_lab_2 from "./image/stem_lab_2.jpg";
import computer_lab_1 from "./image/computer_lab_1.jpg";
import computer_lab_2 from "./image/computer_lab_2.jpg";
import laboratories_2 from "./image/laboratories_2.jpg";
import home_science from "./image/home_science.jpg";
import home_science_2 from "./image/home_science_2.jpg";
import arts from "./image/arts.jpg";
import arts_2 from "./image/arts_2.jpg";
import library_2 from "./image/library_2.jpg";
import cultural from "./image/cultural.jpg";
import cultural_2 from "./image/cultural_2.jpg";
import cultural_3 from "./image/cultural_3.jpg";
import cultural_4 from "./image/cultural_4.jpg";
import bio_lab from "./image/bio_lab.jpg";
import chem_lab from "./image/chem_lab.jpg";
import phy_lab from "./image/phy_lab.jpg";
import infrastructure_1 from "./image/infrastructure.jpg";
import studentMess from "./image/infrastructure/studentMess.jpg";
import faculty_1 from "./image/newImg/1.png";
import faculty_2 from "./image/newImg/2.png";
import faculty_3 from "./image/newImg/3.png";
import faculty_4 from "./image/newImg/4.png";
import faculty_5 from "./image/newImg/5.png";
import faculty_6 from "./image/newImg/6.png";
import aa from "./image/newImg/aa.jpeg";
import a from "./image/newImg/a.jpg";
import b from "./image/newImg/b.jpg";
import c from "./image/newImg/c.jpg";
import d from "./image/newImg/d.jpg";
import e from "./image/newImg/e.jpg";
import biology_lab from "./image/biology_lab.jpg";
import chemistry_lab from "./image/chemistry_lab.jpg";
import physic_lab from "./image/physic_lab.jpg";

import media17 from "./image/newImg/media/media17.jpg";
import gender_img from "./image/gender_img.jpg";
import expression_img from "./image/expression_img.jpg";

import pre1 from "./image/pre1.jpeg";
import pre2 from "./image/pre2.jpeg";

import red from "./image/red_houes.jpg";
import blue from "./image/blue_house.jpg";
import yellow from "./image/orange_house.jpg";
import green from "./image/green_house.jpg";

import expression from "./image/newImg/expression.png";
import ba from "./image/newImg/ba.png";

import media1 from "./image/newImg/media/1.jpg";
import media2 from "./image/newImg/media/2.jpg";
import media3 from "./image/newImg/media/3.jpg";
import media4 from "./image/newImg/media/4.jpg";
import media5 from "./image/newImg/media/5.jpg";
import media6 from "./image/newImg/media/6.jpg";
import media7 from "./image/newImg/media/7.jpg";
import media8 from "./image/newImg/media/8.jpg";
import media9 from "./image/newImg/media/9.jpg";
import media10 from "./image/newImg/media/10.jpg";
import media11 from "./image/newImg/media/11.jpg";
import media12 from "./image/newImg/media/12.jpg";
import media13 from "./image/newImg/media/13.jpg";
import media14 from "./image/newImg/media/14.jpg";
import media15 from "./image/newImg/media/15.jpg";
import media16 from "./image/newImg/media/16.jpg";
import media18 from "./image/newImg/media/bm1.jpg";
import media19 from "./image/newImg/media/bm2.jpg";
import media20 from "./image/newImg/media/bm3.jpg";
import media21 from "./image/newImg/media/media21.jpg";
import media22 from "./image/newImg/media/media22.jpg";
import media23 from "./image/newImg/media/media23.jpg";
import media24 from "./image/newImg/media/media24.jpg";
import media25 from "./image/newImg/media/media25.jpg";
import media26 from "./image/newImg/media/media26.jpg";
import media27 from "./image/newImg/media/media27.jpg";
import media28 from "./image/newImg/media/media28.jpg";
import media29 from "./image/newImg/media/media29.jpg";
import media30 from "./image/newImg/media/media30.jpg";
import media31 from "./image/newImg/media/media31.jpg";
import media32 from "./image/newImg/media/32.jpeg";
import media33 from "./image/newImg/media/33.jpeg";
import media34 from "./image/newImg/media/34.jpeg";
import media35 from "./image/newImg/media/35.jpeg";

import sports_club_icon from "./image/clubs/sportsClubIcon.png";
import anti_bullying_icon from "./image/clubs/antiBullyingIcon.png";
import environment_club_icon from "./image/clubs/environmentClubIcon.png";
import health_club_icon from "./image/clubs/healthClubIcon.png";
import value_club_icon from "./image/clubs/valueClubIcon.png";
import social_impact_club_icon from "./image/clubs/socialImpactClub.png";
import gender_club from "./image/clubs/genderClub.png";
import english_club_icon from "./image/clubs/englishClub.png";
import gender from "./image/clubs/gender.jpg";

//
import orientation_1 from "./image/orientation_1.jpg";
import orientation_2 from "./image/orientation_2.jpg";
import orientation_3 from "./image/orientation_3.jpg";
import orientation_4 from "./image/orientation_4.jpg";
import book from "./image/book.jpg";
import waste from "./image/waste.jpg";
import waste_2 from "./image/waste_2.jpg";
import paper from "./image/paper.jpg";
import republic_1 from "./image/republic_1.jpg";
import republic_2 from "./image/republic_2.jpg";
import republic_3 from "./image/republic_3.jpg";
import covid from "./image/covid.jpg";
import mother from "./image/mother.jpg";
import first_aid from "./image/first_aid.jpg";
import basant_1 from "./image/basant_1.jpg";
import basant_2 from "./image/basant_2.jpg";
import pareeksha from "./image/pareeksha.jpg";
import pareeksha_2 from "./image/pareeksha_2.jpg";
import heritage from "./image/heritage.jpg";
import farewell from "./image/farewell.jpg";
import farewell_2 from "./image/farewell_2.jpg";
import environment from "./image/environment.jpg";
import fashion from "./image/fashion.jpg";
import fire from "./image/fire.jpg";
import donation_1 from "./image/donation_1.jpg";
import donation_2 from "./image/donation_2.jpg";

import { FaSchool } from "react-icons/fa";

//
export const sidebar_data_vision = [
  {
    title: "Vision & Mission",
    navigation: "vision-and-mission",
  },
  {
    title: "Message From Leadership",
    navigation: "message",
  },
  {
    title: "Academics & Curriculum",
    navigation: "nep",
  },
  {
    title: "Beyond Curriculum",
    navigation: "house-clubs",
  },
  {
    title: "Sports & Fitness",
    navigation: "sports",
  },
  {
    title: "Faculty & Staff",
    navigation: "faculty-staff",
  },
  {
    title: "Events & Happenings",
    navigation: "events",
  },
  {
    title: "Parents' & Students' Voice",
    navigation: "parents",
  },
  {
    title: "Infrastructure & Facilities",
    navigation: "infrastructure",
  },
  // {
  //   title: "Life In Boarding House",
  //   navigation: "boarding",
  // },
  {
    title: "Achievements and Laurels",
    navigation: "awards",
  },
  {
    title: "News & Media Coverage",
    navigation: "news",
  },
  {
    title: "Admissions",
    navigation: "admissions",
  },
];
export const academics_sidenav = [
  {
    title: "NEP 2020 Ready School ",
    navigation: "nep",
  },
  {
    title: "Curriculum ",
    navigation: "curriculum",
  },
  {
    title: "Pedagogical Approach",
    navigation: "pedagogi",
  },
  {
    title: "Life Skill Curriculum",
    navigation: "life-skill",
  },
  {
    title: "Assessment",
    navigation: "examination",
  },
  {
    title: "Skill & Vocational Subjects",
    navigation: "skill-vocational",
  },
  {
    title: "Results",
    navigation: "results",
  },
  {
    title: "Booklist",
    navigation: "books",
  },
];
export const beyond_sidenav = [
  {
    title: "Houses & Clubs",
    navigation: "house-clubs",
  },
  {
    title: "Student Council",
    navigation: "student-council",
  },
  {
    title: "Trips, Excursions & Field Visits",
    navigation: "trips",
  },
  {
    title: "NCC, Band, Scouts & Guides",
    navigation: "ncc-scouts",
  },
];
//
export const vision_mission = [
  {
    id: 1,
    title: "Vision",
    description:
      "We at Hermann Gmeiner school  believe in scholarly and accomplished learning. To provide all our students with advanced and qualified academic education along with the knowledge and experience in sports and co-curricular activities has always been our priority. Blending leisure with technology and creating a holistic experience for the educators to have the finest learning experience has been the precedence of our institution. We tend to upskill and upgrade all the children at the primary age with the will and spirit to be the best and brightest version of themselves. This helps them to grow and develop individually and in the society to become a good and contributing citizen of our country. ",
    description_image: [vision],
  },
  {
    id: 2,
    title: "Mission",
    description:
      "The mission of  Hermann Gmeiner school is to create a safe space that nurtures the cerebral, social, emotional, physical, spiritual and academic development of every child. Our school strives to encourage and develop a sense of confidence and self-assurance in every juvenile. With a wide yet safe environment along with empathetic and patient staff, we want to empower our students and help them rise at every step of learning. By inculcating family and society values along with practical and theoretical knowledge, the children would learn the significance of selfhood and brotherhood.",
    description_image: [mission],
  },
];
export const message_data = [
  {
    id: 1,
    title: "President",
    name: "Rakesh Jinsi",
    occ: "SOS Children’s Villages of India",
    image: JinsiPhoto,
    description:
      "Rakesh Jinsi, an engineer by degree, has 41 years of experience in leadership roles at several institutions, including SOS Children's Villages. Presently running Khushboo, a daycare centre for children with severe mental and multiple disabilities, he has always been active in national level bodies such  as the National Human Rights Commission and Voluntary Action Network of India (VANI), the Apex body of the NGO sector.",
    extra_description: [
      `"I firmly believe that each and every child deserves an education that is advanced, detail-oriented yet nurturing and holistic. As President of SOS Children’s Villages of India, I wish to provide the best possible learning experience to the future leaders of our country, so that all children are able to reach their maximum potential and achieve their dreams. Students end up spending most of their childhood within classrooms, so it is essential that within those walls they are imparted proper theoretical knowledge, practical aptitude, communal values, essential life skills, and an understanding of how the world works and their place in it. ,
      "Adequate grooming and learning of children at a young age leads to them being strong, confident, hard-working and intelligent individuals, attributes that I deem necessary for the new era of technology and scientific progress we are heading towards."`,
    ],
  },
  {
    id: 2,
    title: "Secretary General",
    name: "Sumanta Kar",
    occ: "SOS Children’s Villages of India",
    image: SumantaPhoto,
    description:
      "Education at SOS Hermann Gmeiner Schools has always meant the Holistic Development of a student – we believe that each child is entitled to the best possible skill set for their future, therefore, each child entrusted with us will receive an education that is in its finest form. ",
    extra_description: [
      "Education is preparation for life, it should be an enjoyable, interactive and ongoing process to make the students confident young adults, sensitive to global issues, committed citizens who are aware of the happenings of the world and sensible individuals with a positive attitude. ",
      "Students should have a strong character and a good understanding of their humane and emotional sides. All our Hermann Gmeiner Schools have been actively engaged in relating to, understanding and working with communities around our schools.",
      "Safeguarding of children remains a top priority and they are nurtured through a theme-based curriculum and actively engage in experiential learning. Our teachers and principals play a pivotal role in the development of each child. Our alumni are our brand ambassadors, who leave a lasting footprint in whatever profession they choose to pursue. Each student who joins us becomes a future world leader, ready to bring progressive change and make our nation proud.",
    ],
  },
  {
    id: 3,
    title: "Head- Education & Child Development",
    name: "Debaroti Bose",
    occ: "SOS Children’s Villages of India",
    image: DebarotiPhoto,
    description:
      "I believe that school should be the experience of a lifetime. Our school experience sets the stage for success later in life and provides a lifetime of positive memories for our students. We are proud of our continuous goal of providing an excellent education for our students.We have a dynamic, hardworking faculty who are involved in education to benefit the students.The entire gamut of school education is transforming the teaching-learning process. ",
    extra_description: [
      "In the modern concept of schooling, teaching has become redundant, paving the way for the facilitation of learning through knowledge sharing. The wave of digital learning and cloud computing is reaching new heights.",
      " In all this whirlwind of new-age technology, it is important to charge ahead but with one’s head firmly on one’s shoulders.  Hermann Gmeiner school strives to inculcate moral values, manners, responsibilities among students so that they may build a world free of all the evils.",
      "Students here are encouraged to dream, groomed to give wings to their dreams, to take off and explore the unexplored. The plethora of interesting & exciting opportunities available to youngsters today often leave them with an array of things to do.",
      " It is important therefore to help them build their focus on the subject that draws them most and gives shape to their vision. A student is made to blossom intellectually, morally, and spiritually. As Swami Vivekananda said, “Take up one idea. Make that one idea your life – think of it, dream of it, and live on that idea. Let the brain, muscles, nerves, every part of your body, be full of that idea, and just leave every other idea alone. This is the way to success.”",
    ],
  },
  {
    id: 4,
    title: "Principal",
    occ: "Hermann Gmeiner School, Bhimtal",
    name: "K.D. Singh",
    image: subrata,
    description:
      "We live today in a world that is very different from the one we grew up or educated in. The world today is changing at such an accelerated rate where we as educators need to pause and reflect on this entire system of education.",
    extra_description: [
      `Today’s students are the future of the entire world and they must be educated in the way that they could face any type of futuristic situation.
    Our school is well equipped to prepare our children to face the challenges that the future holds and work at implementing a well-balanced curriculum to ensure that the children who walk into the campus of  Hermann Gmeiner school, Bhimtal will not just be educated but truly be prepared to face life's challenges.
    I appreciate the staff and students of all faculties who use various modes of teaching learning process to reach the students. Even in the situation of Covid-19 pandemic, our faculties showed a lot of enthusiasm and patience in teaching the learning process and ensured maximum attendance in their online classes.`,
      `As long as our ideas are expressed, we can be sure of learning as everything begins with communication. Learning is not a process limited to schools and colleges only, nor does it end with the conclusion of one's school career. It is indeed a lifelong process.
    There is no end to education. It is not that you read a book, pass an examination, and finish with education. The whole of life, from the moment you are born to the moment you die, is a process of learning.`,
    ],
  },
];
export const infrastructure = [
  {
    id: 1,
    title: "Infrastructure",
    description: `The infrastructure of Hermann Gmeiner School Bhimtal leaves no stone unturned to look after the comfort of the students. The school has 40 ventilated classrooms, 10 labs, a library, a staffroom, a clinic and so on. The infrastructure provides the perfect ambiance for learning and teaching. The green and lush playgrounds provide a perfect front to the school.
    The school ensures that the comfort of students is looked after and all the necessities are taken care of. The classrooms are well furnished and the labs have all the required equipment. The school ensures that students are at ease in school and face no problems. This is to make sure that the learning process is not interrupted.
    `,
    description_image: [slider_1],
  },
  {
    id: 6,
    title: "Spacious Classrooms",
    description: `The classrooms are equipped with the latest technology including an electronic interactive touch screen board, projector and computer with all lessons, activities, practicals, animations, videos, exercises, quizzes etc. uploaded & regularly updated by an expert team to develop the concepts and make the learning process interesting and joyful.
    Classrooms are full of display boards that are filled with creative work, drawings, and paintings. It helps to promote cognitive learning among students and makes the classrooms a colourful and vibrant place to study. The chairs and tables in the classroom are extremely comfortable to support long sitting hours.
    `,
    description_image: [spacious_classroom],
  },
  {
    id: 2,
    title: "Computer Lab",
    description: `The school has a highly sophisticated computer lab. It is equipped with a computing facility with 40 i3 computers connected through the Local Area Network as well as Wifi. The high-speed internet connectivity of 11 Mbps (24*7) to access vast intellectual resources. These facilities are an integral part of the curriculum. Students are expected to use computers in their day-to-day studies to meet the challenges of modern learning and to keep pace with the global scenario
      
Tech skills have become a very important factor in recent times. Students are trained to use and incorporate computers into their day-to-day work. This is done to keep them updated with the recent global trends and expectations of companies. The school ensures that the students are equipped with the latest technology and know how to use them.
    `,
    description_image: [computer_lab],
  },
  {
    id: 3,
    title: "Biology Lab ",
    description: `Students are encouraged to learn through experiments and grasp diverse ideas beyond their textbooks in the Biology lab, which is separate from traditional classroom instruction. Experiments and observations make learning more engaging and enjoyable. Experiments are fun for students because they learn something new from the outcomes. Every student in grades I through XII has the opportunity to do the practical under the supervision of the professors.`,
    description_image: [biology_lab],
  },
  {
    id: 4,
    title: "Chemistry Lab ",
    description: ` Hermann Gmeiner school has a fantastic Chemistry lab with all of the latest and best-in-class apparatus and chemicals, as well as a wonderful learning atmosphere where students do practicals under the supervision of the lecturers. All precautions are followed, and the youngsters learn the fundamentals of chemistry by doing their own experiments, observing, and drawing conclusions.`,
    description_image: [chemistry_lab],
  },
  {
    id: 5,
    title: "Physics Lab",
    description: `Physics lab of  Hermann Gmeiner school has all the up-to-date equipment. This lab assists students in developing a broad range of basic experimental physics and data analysis skills and tools. It is also intended for each student to conduct experiments under the supervision of Physics lecturers. The whole operation of the Physics lab is conducted in accordance with established safety standards.`,
    description_image: [physic_lab],
  },
  {
    id: 7,
    title: "Art & Craft Room (The Heart of Creativity)",
    description: `The periods in the Art Room offer each child a learning experience that teaches him or her to be a risk-taker, thinker, inquirer, and a good communicator in a safe environment. The Art teachers help the students in drawing, painting, crafts work, best out of waste, and clay modelling. Providing the students with experiences in art, craft and design enables them to reflect critically on their own and others’ work. We aim that they learn to think and act as artists, working creatively and intelligently.`,
    description_image: [arts],
  },
  {
    id: 8,
    title: "Tablet Based Language Lab",
    description: `To be able to communicate well is the order of the day. The English Language Laboratory is an initiative to enhance the four basic language skills namely listening, speaking, reading, and writing. The listening and speaking skills are practised here intensively. The lab is equipped with the latest model tablets for every student to enhance their proficiency.
    This lab strives to improve the communication and presentation skills of the students. Communication skills are a prerequisite requirement in global scenarios. It aims to enhance these skills through fun activities, training sessions, and enthralling games.`,
    description_image: [tablet_class],
  },
  {
    id: 8,
    title: "School Mess",
    description: `The school runs a well-organised, modern, and hygienic mess that can cater to 200 persons at a time and is fully equipped with the latest cooking techniques with the help of courteous and experienced staff. It ensures that healthy, hygienic and nutritious food is being served to the students.
    A balanced diet is served to the students which include Veg./Non Veg. as per the choice of borders under the supervision of a dietician. A Mess Committee having teachers and students’ representatives decides the menu. The menu has a vast variety of options keeping in mind the requirements of the students. It ensures that the students are content.`,
    description_image: [studentMess],
  },
  {
    id: 8,
    title: "School Canteen",
    description: `Students can get coupons worth Rs. 60/- per week to avail the Canteen facility. The canteen provides snacks, refreshments, soft drinks, etc. when students want them for a change. Parents/students are not allowed to open an account or deposit the money with the canteen contractor. Only authorised and approved items are provided to the students.
    This is done to provide a change to the students from the daily routine of food. The students are provided limited coupons only, keeping their nutritional concerns in mind. The canteen provides various types of mouth-watering and flavoursome refreshments and options to the students.`,
    description_image: [school_canteen],
  },
  {
    id: 8,
    title: "Library",
    description: `The Library provides a very conducive atmosphere for reading. New books are added to the library every year and it subscribes to a number of Journals. Silence is always to be observed in the library.  Hermann Gmeiner school Techno Library, a digital portal enables the students to view the e-books of all  Hermann Gmeiner school. Students can access the  Hermann Gmeiner school techno library 24X7 and can read the books of their choice by using their password and ID. Books include- Encyclopaedias, subject books (NCERT) storybooks, novels, religious books, personality development, sample questions papers, a Question bank, and assignments made by  Hermann Gmeiner school teachers, animations, videos, etc.`,
    extra_description: [
      `We also have a tie-up with Encyclopaedia Britannica to Feed students’ curiosity and provide additional sources for research with Britannica’s trusted, highly acclaimed, non-fiction e-books. Featuring 95 titles with many more added each year, nonfiction ebooks are available for art, early childhood, language arts, maths, science, social studies, and other school subjects, Brimming with brilliant illustrations and vivid photographs, each ebook helps develop the essential literacy and critical thinking skills that students need to succeed.`,
    ],
    description_image: [library],
  },
  {
    id: 3,
    title: "Playgrounds",
    description: `There are many spacious and luxurious playgrounds in  Hermann Gmeiner school Bhimtal.These act as rejuvenation centres for the students. Here, students are trained to play different kinds of games. This is the place where students bond with each other and relieve their day-long stress.
    This helps to ensure that the children are healthy mentally as well as physically. It also develops a healthy competitive spirit among students. Students are challenged to push themselves out of their comfort zone and learn to be achievement-oriented. The school has several playgrounds:
    `,
    features: [
      "Basketball Court",
      "Volleyball Court",
      "Football Court",
      "Skating Rink",
      "Badminton Court",
      "Table Tennis Room",
      "Swimming Pool",
      "Gym",
      "Cricket Net",
      "Billiards",
    ],
    features_image: [playground_1],
  },
  // {
  //   id: 11,
  //   title: "Transportation",
  //   description: `School transport is available from fixed points to the school and back for the day scholars, subject to the availability of an adequate number of children on a particular route. The objective is to simplify the travelling process for students and to reduce absenteeism.
  //   The buses are operated by trained drivers and professionals to ensure the safety of students. Our buses are well spaced and ventilated so that the students are comfortable and can commute easily. The buses have all safety and first aid equipment which could be used in cases of emergency.
  //   `,
  //   description_image: [transportation],
  // },
];
export const career_guidance = [
  {
    id: 1,
    title: "Career Counselling Workshop",
    description: `The future depends on what you do today." Hermann Gmeiner School organised a Career Counseling and Guidance programme to direct the students to move on the right path. Parents from different fields and resource persons were invited to share their journey of success with students so that they could learn different aspects of success. The students engaged in the session, asked queries to have better vision for their future and were satisfied with the guidance.`,
    description_image: [career_1],
  },
  {
    id: 2,
    title: "",
    description: `Career building is a deep rooted process. It becomes tedious for adolescents to choose an appropriate career with great placements and a bright future. School plays an important role in this ever-growing process of finding oneself and establishing oneself.`,
    description_image: [career_2],
  },
  {
    id: 3,
    title: "",
    description: ` ITM Institute of Design & Media conducted a career counselling workshop on career opportunities in online world like “YOUTUBE, VFX, ANIMATION", etc. on Feb 16th ,2022. The key speaker was Ms. Jyotsna Raghunathan, who is a storyteller and a film maker with over 20 years of experience in writing scripts, directing films and working in the educational sector of media and mass communication. She has also specialized in curriculum design and development, as well as making films and writing scripts under her own company banner, Panvision. `,
    description_image: [career_4],
  },
  {
    id: 4,
    title: "",
    description: ` The workshop started with her addressing the issue of confusion being created in youngsters' mind while choosing a career. She talked about the varied courses available for all the streams and how these can be helpful in fulfilling their dreams. The students were attentive and interactive and asked queries like the exams to be taken up, combinations of subjects available, options offered, placements etc. It was an enriching session for the students of grade XII.`,
    description_image: [career_5],
  },
];
export const admissions = [
  {
    id: 1,
    title: "Admission Procedure",
    description:
      "Hermann Gmeiner School does not discriminate against any student on the basis of religion, race, caste, birth etc. Admission procedure in our school is completely based on the compatibility of the kid and we always follow complete legal and step by step procedures laid by the government. In regard to reservation for students of BPL/EWS category, it is governed by the RTE Act.",
    features: [
      "Admission in classes Nursery to V will be done on a 'First-Come First-Served Basis' and after a basic test.",
      "Admission in classes VI to VIII will be done on the basis of basic tests for language, mathematics & science concepts.",
      "For admission in class IX, the students from outside Hermann Gmeiner Schools have to appear in an Entrance Examination of 2.5 hrs. in the subjects of Hindi, English, Mathematics and General Science.",
      "For admission in class XI, counselling will be conducted for Hermann Gmeiner School’s students and for other students, entrance tests will be conducted in the subjects offered, subject to the availability of vacancy.",
      "For admission to the class, XI counselling will be conducted for  Hermann Gmeiner school students, and for other students entrance tests will be conducted in the subjects offered, subject to the availability of vacancies. Admissions to X and XII are strictly governed by CBSE norms",
      "Admission in class X and XII is strictly governed by the norms of affiliating boards.",
      "Interview of parents and interaction of the child with a specific class teacher is mandatory.",
      "The Principal's/Admission committee's decision will be final and binding with regards to all admissions.",
      "Admission is granted as and when vacancies are available in any class.",
      "Admission is granted strictly on merit of qualified candidates.",
    ],
  },
  // {
  //   id: 2,
  //   title: "Age Criteria",
  //   features: [
  //     "LKG – A child completing 4 years of age as of 1st April of the Academic year.",
  //     "UKG - A child completing 5 years of age as of 1st April of the Academic year.",
  //     "Class 1 – A child must be 6 years of age as of 1st April of the Academic year.",
  //   ],
  // },
  // {
  //   id: 3,
  //   title: "Documents to be Attached",
  //   description: ``,
  //   features: [
  //     "Attest a Photocopy of Birth Certificate for LKG, UKG, and class I.",
  //     "Attested photocopy of Progress Report of the child indicating academic performance and participation in co-curricular activities in the school last attended.",
  //     "Registration with CBSE, in case, admission is sought in classes X and XII on the genuine transfer of parents in addition to (2) & (3) above and a copy of the transfer order of the parents.",
  //     "EWS/ BPL certificate from competent authority (if applicable).",
  //     "Character certificate in original from the head of the previous school.",
  //     "Address Proof of the parents/child",
  //     "Income certificate of the parents",
  //     "SC/ST/OBC Certificate from the competent authority (if applicable)"
  //   ],
  // },
  // {
  //   id: 3,
  //   title: "Rejoining School after Absence",
  //   description: `For admission in a class, XI counseling will be conducted for  Hermann Gmeiner school students, and other students' entrance tests will be conducted in the subject offered, subject to the availability of vacancies. Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.
  //   `,
  //   features: [
  //     "A student who remains absent for more than 10 days without prior information may get his/her name struck off from school rolls.",
  //     "The student will only be permitted to attend classes following an absence from the school for any reason including illness if they bring an application from the parent /guardian or a proper medical certificate from the qualified doctor if under medical treatment.",
  //     "In all other cases, the child is readmitted with a payment of 50% of the admission fee.",
  //   ],
  // },
  // {
  //   id: 4,
  //   title: "Admission Criteria",
  //   description: `Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.`,
  //   features: [
  //     "Admission will be granted to parents, who are in service, Govt. Employee, Bank employee, working in Public Sector Units (We should avoid giving admissions to daily wagers)",
  //     "Monthly income of parents should be a minimum of INR 50,000/- P.M.",
  //     "Interviewing parents is mandatory. Interaction of the child with the specific class teacher is mandatory.",
  //     "Any one of the parents should be educated having minimum qualifications as ‘Graduate’ or equivalent. In case a parent is a landlord/ farmer, he has to produce the income certificate or any other document from a competent authority to show his/her monthly income as minimum 50,000/- P.M.",
  //     "Admission to the school is made without any distinction of religion, race, caste, place of birth, etc.",
  //     "Admission is granted as and when vacancies are available in any class.",
  //   ],
  // },
  // {
  //   id: 5,
  //   title: "Admission Committee",
  //   description: ``,
  //   features: [
  //     "Class Teacher (for interaction with the child)",
  //     "Written test as per grade level",
  //     "Headmistress / HeadMasters and Counsellor (First interaction of the parents and student).",
  //     " Final approval to be given by the Principal after interaction with the child and the parents with verification of all documents.",
  //     "Class VI onwards, final approval to be given by Head ECD.",
  //   ],
  // },
  // {
  //   id: 7,
  //   title: "Admission to Class XI",
  //   description: `The entrance test will be conducted in the basic subjects of Class X studied by the student (subject to the availability of vacancy).
  //   To be eligible for admission the child should get at least 65% in our school entrance exam. Students who qualified for their Class X from a recognized state board are eligible for the examination. However, it is mandatory to score the minimum percentage to be eligible for the stream chosen.
  //   `,
  // },
  // {
  //   title: `For admission to Science Stream with Mathematics`,
  //   features: [
  //     "A minimum of 65% in Mathematics Standard Paper (New Admissions)",
  //     "A minimum of 65% in Science (New Admissions)",
  //     "Aggregate marks in 5 subjects including Mathematics & Science must be 65% (New Admissions)",
  //     "For internal admissions, aggregate should be 60%.",
  //   ],
  // },
  // {
  //   title: `For admission to Science Stream without Mathematics`,
  //   features: [
  //     "A minimum of 65% in Science (New Admissions)",
  //     "Aggregate marks in any 5 five subjects including Mathematics & Science must be 65% (New Admissions)",
  //     "For internal admissions aggregate should be 60%",
  //   ],
  // },
  // {
  //   title: `For Commerce Stream`,
  //   features: [
  //     "A minimum of 55% aggregate marks in any 5 subjects",
  //     "Mathematics can be offered if the student has obtained a minimum of 60% marks in Mathematics standard paper",
  //   ],
  // },
  // {
  //   title: `For Humanities Stream`,
  //   features: [
  //     "A minimum of 55% aggregate marks in any 5 subjects ",
  //     "A minimum of 55% in Social Studies",
  //   ],
  // },
  // {
  //   id: 10,
  //   title: "Admission to Class IX",
  //   description: `For admission in Class IX, the student must have finished his/her regular study in class VIII. The admission process will be conducted only for the students who were studying in any institution affiliated by state governments or UT governments or other certified boards.`,
  // },
  // {
  //   id: 11,
  //   title: "Admission to Classes Nursery to VIII",
  //   description: ``,
  //   features: [
  //     `Admission to Nursery: Students must complete 3+ years of age as of 31st March of the academic year.`,
  //     `Admission to Class I: Students must complete 6+ years of age as of 31 March, of the academic year.`,
  //     `Admission to classes II-VIII: Report Card of the child has to be submitted with an equivalent of at least 60% in previous classes. TC to be collected from every child and the reason for shifting/transfer to be verified.`,
  //   ],
  // },
  // {
  //   id: 12,
  //   title: "",
  //   description: `Those seeking admission should produce the following:`,
  //   features: [
  //     "Birth Certificate",
  //     "A School Leaving Certificate from the school last attended.",
  //     "Progress Report of the child indicating academic performance and participation in co-curricular activities in the school last attended.",
  //     "Registration with CBSE, in case, admission is sought in Class X and XII on the genuine transfer of parents in addition to (2) & (3) above.",
  //     "4 Passport size photographs",
  //     "Address Proof",
  //     "Medical Certificate",
  //     "SC/ST/EWS/BPL Certificate as per the process and rules of the schools.",
  //   ],
  // },
  {
    id: 13,
    title: "Admission Criteria",
    color: "#FF1212",
  },
  {
    id: 13,
    title: "Admission to Nursery",
    description: `Students must complete 3+ years of age as on 31st March of the academic year.`,
  },
  {
    id: 13,
    title: "Admission to Class l",
    description: `Students must complete 6+ years of age as on 31st March, of the academic year`,
  },
  {
    id: 13,
    title: "Admission to class II-VIII",
    description: `The Report Card of the child has to be submitted with the equivalent 60% in previous classes. T.C. to be collected from each and every child above Class I and reason for shifting/transfer to be verified as per state education board norms.`,
  },
  {
    id: 13,
    title: "Admission to Class IX",
    description: `
    Admission to Class IX
    Entrance Test will be conducted in the subjects desired by the student, subject to the availability of vacancy. To be eligible for admission the child should get 60% in our school entrance exam. Students who qualified their Class X from a recognized state board are eligible for the examination. However, it is mandatory to score the minimum percentage to be eligible for the stream chosen.`,
  },
  {
    id: 13,
    title: "",
    description: "Commerce Stream",
    features: [
      "A minimum of 55% aggregate marks in any 5 subjects.",
      "Mathematics can be offered if the student has obtained minimum 60% marks in Maths standard papers.",
    ],
  },
  {
    id: 13,
    title: "",
    description: "Humanities Stream",
    features: [
      "A minimum of 55% aggregate marks in any 5 subjects.",
      "55% in Social Studies..",
    ],
  },
  {
    id: 13,
    title: "Documents Required",
    description: `Those seeking admission, one should produce the following: For Kindergarten (Nursery to K.G.-II) :`,
    features: [
      "Birth Certificate issued by the competent authority",
      "Parent ID",
      "Address Proof",
      "Child’s Photographs (Two)",
      "Income Certificate",
      "Student’s Health Certificate",
    ],
  },
  {
    id: 13,
    title: "From class I onward",
    description: ``,
    features: [
      "School Leaving Certificate/ Transfer Certificate duly countersigned by authorities from the last school attended",
      "Progress Report of the child indicating Academic performance and participation in co-curricular activities in the school last attended",
      "Proof of residence (photocopy of any one- Ration Card/ Domicile Certificate/ Electricity Bill/ Water Bill/ MTNL Telephone bill)",
      "Parent’s ID (Aadhar Card/ Voter Card/ Passport)",
      "Four Passport Size Photographs",
      "EWS/BPL Card, if applicable (Photocopy)",
      "SSMID and FMID of the Student (Photocopy)",
      "Bank Passbook copy of the Student (Photocopy)",
      "Aadhar Card copy of the Student",
      "Registration with CBSE or MPBSE, in case admission is sought in Class X and XII on genuine transfer of parents in addition to (1) & (2) above",
      "Income certificate",
      "Student’s Health Certificate",
      "Caste certificate",
    ],
  },
  {
    id: 13,
    title: "Admission Approval",
    description: ``,
    features: [
      "The Principal & Admission Committee together will give the final approval of admission after interaction with the child and the parents with verification of all documents.",
      "Class VI onwards, all admissions to be approved by Head ECD.",
    ],
  },
];

export const curriculum = [
  {
    title: "Primary Curriculum",
    navigation: "primary",
    text: "Designed to nurture the child in all dimensions of his or her life",
    image: primary,
  },
  {
    title: "Secondary Curriculum",
    navigation: "secondary",
    text: "A skills-focused programme that provides students with a broad and balanced knowledge of key subjects",
    image: secondary,
  },
  {
    title: "Senior Secondary Curriculum",
    navigation: "sr-secondary",
    text: "Encompassing learning areas to foster competences in our students",
    image: sr_secondary,
  },
];
export const pre_primary_data = [
  {
    id: 1,
    description:
      "Pre-Primary is the foundational step of education where the learning is imparted to the age group of 3 to 6 years. The curriculum is a blend of both Experiential and Project based learning where the students have hands on experience of the learning resulting them to retain the learning lifelong. We want children to interact with each other and explore various aspects of life which includes taking care of themselves, awareness towards environment, proper physical/mental growth and development, use of mathematical concepts in day-to-day life experiences.",
    description_image: [pre1],
  },
  {
    id: 2,
    title: "",
    description:
      "The benefits of such deep learning at this stage are memory retention, life-skill development and emotional growth. Activity based and Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected together and integrated with a thematic approach making teaching and learning active and interactive. The starting ground is the uniqueness of the child, active learning, and integration in a group and a collective partnership. This way learning becomes meaningful and a child makes deeper connection with the world.",
    description_image: [pre2],
  },
];
export const primary_data = [
  {
    id: 1,
    title: "Pre-Primary Curriculum",
    description:
      "Pre-Primary is the foundational step of education where the learning is imparted to the age group of 3 to 6 years. The curriculum is a blend of both Experiential and Project based learning where the students have hands on experience of the learning resulting them to retain the learning lifelong. We want children to interact with each other and explore various aspects of life which includes taking care of themselves, awareness towards environment, proper physical/mental growth and development, use of mathematical concepts in day-to-day life experiences.",
    description_image: [pre1],
  },
  {
    id: 2,
    title: "",
    description:
      "The benefits of such deep learning at this stage are memory retention, life-skill development and emotional growth. Activity based and Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected together and integrated with a thematic approach making teaching and learning active and interactive. The starting ground is the uniqueness of the child, active learning, and integration in a group and a collective partnership. This way learning becomes meaningful and a child makes deeper connection with the world.",
    description_image: [pre2],
  },
  {
    id: 1,
    title: "Primary Curriculum",
    description:
      "The primary curriculum is a blend of both Experiential and Project-based learning where the students have hands-on experience of the learning resulting in them retaining the learning lifelong. Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected and integrated with a thematic approach making teaching and learning active and interactive.",
    description_image: [primary_curriculum],
  },
  {
    id: 2,
    title: "Interactive Classes ",
    description:
      "Interactive classes encourage students’ participation and engagement for better learning and overall development of a child. As the primary classes are the foundational years of education, Hermann Gmeiner School has well-equipped Interactive board classrooms, open play areas, activity corners, reading corners, etc. to reduce dependency on textbooks and increase focus on learning by interacting. Students do a lot of activities like making floor games to learn tables and other numeracy-related topics. The children take part in poem recitations, debates, speeches, and group discussions which make them more confident and learn from their peer group.",
    description_image: [interactive],
  },
  {
    id: 3,
    title: "  Happy Classrooms",
    description:
      "Three to seven years are crucial in everyone’s life, here learning grows through analyzing and evaluating an issue, and when it is done through activities and play the way, the method then creates happy classrooms. Learning is done including creativities and activities for the students, making them happy in the course of learning. Students do recreational activities like drawing, painting, making postcards, puppetry, and showcase their talent through ME time.",
    description_image: [happy_classroom],
  },
];
export const secondary_data = [
  {
    id: 1,
    description: `At this stage, students have the choice of a curriculum leading to the All India Secondary School Examination conducted by the Central Board of Secondary Education (CBSE), New Delhi. At Secondary school we offer:
`,
    features: [
      "Languages (English & Hindi)",
      "Science",
      "Mathematics",
      "Social Studies",
    ],
    features_image: [secondary_1],
  },
  {
    id: 2,
    title: "",
    description:
      "The secondary curriculum is aimed for the holistic development of students so that they develop effective communication and critical thinking skills. The syllabus is devised in a manner which ensures the students strengthen their work habits.The subjects chosen in this curriculum prepares the students for further studies.",
    // description_image: [secondary2],
  },
];
export const sr_secondary_data = [
  {
    id: 1,
    description: `The Senior Secondary School curriculum further builds on the skills and knowledge acquired by the students in the earlier classes. Life skills like social skills, thinking skills – critical, creative, decision making and problem-solving, emotional skills, and values are embedded in the curriculum. The school offers all three streams at the Senior Secondary level- Science (Medical and Non-Medical), Commerce, and Humanities.`,
    description_image: [sr_secondary_1_1],
  },
  {
    id: 2,
    title: "",
    description: `A combination of subjects helps the students in their niche and areas they want to particularly focus on. It helps them to filter out the vast range of available opportunities. Professional guidance and counselling are very crucial at this stage. The school trains its staff according to the needs and demands of the children.
    The school offers academics with a subject combination as: `,
  },
  {
    description: "srsec",
  },
];
// export const alumni_data = [
//   {
//     id: 1,
//     title: "Nikhel Bakshi",
//     occupation: "Youngest Chief Flying Instructor & Trainer",
//     description: `Youngest Chief Flying Instructor & Trainer
//     I got the privilege of being a part of Hermann Gmeiner School, where I not only grew physically but also emotionally and psychologically. The lessons and values taught here are embedded deep into my mind and are a part of my personality. I will cherish the experiences and memories of the school and hon'ble teachers forever. From 2006 - 2016 (10 years), I have been the Youngest Chief flying Instructor/Trainer and have trained more than 300 pilots who are now flying in different airlines in India and in foreign airlines as well. From 2017 till date, I am working as a Captain in Indigo Airlines and holding a management post as well as that of a Fleet Coordinator (Delhi Base).
//     `,
//     image: nikhel,
//   },
//   {
//     id: 2,
//     title: " Ankita Lal",
//     occupation:
//       "Additional Senior Civil Judge in Saket District Courts, New Delhi.",
//     description: `Additional Senior Civil Judge in Saket District Courts, New Delhi.
//     School plays a very important part in our lives. What we are today is because of what we have learnt from our schools. I feel blessed being a part of Hermann Gmeiner School Faridabad. The school gave me an environment which helped me excel in my academic goals. The teachers were more helpful than I had ever expected. Not only did the school cater perfectly to my academic needs but also helped me build my personality and co-curricular skills.
//     `,
//     image: ankita,
//   },
//   {
//     id: 3,
//     title: "Sandeep Kak",
//     occupation: "General Manager - Otis Elevators",
//     description: `General Manager - Otis Elevators (Bangalore, Karnataka)
//     The School is a stepping stone, responsible for the overall development of a naive and innocent child into a responsible, sensible, and law-abiding citizen. It is a home away from home for all its students and for me too,  Hermann Gmeiner school was my extended family for five years. The school was responsible for shaping my character and personality. At  Hermann Gmeiner school, I and most of my peers have always felt that teachers were our friends, philosophers, and guides. They cared about every student & always tried to give the best advice.
//     `,
//     image: sandeep,
//   },
//   {
//     id: 4,
//     title: "Bharat Bansal",
//     occupation: "Entrepreneur in the USA",
//     description: `Entrepreneur in the USA
//     Hermann Gmeiner School has been my alma mater for a long time. I have been extremely lucky to have studied here and have made lifelong friends in this school. FIGS has given a very unique perspective to me and my batch mates, due to its unique setting & approach to education in both curriculum and life skills. I wish the school all the best and will also be available to mentor any junior students if needed.
//     `,
//     image: bharat_bansal,
//   },

//   {
//     id: 5,
//     title: "Subhadeep Sanyal",
//     occupation: "Vice President - Omnivore Partners",
//     description: `Vice President - Omnivore Partners
//     I earned a B.Sc. (Hons) in Agriculture from CCS HAU, Hisar where I was the batch topper (2005-09). I completed my Masters degree from IIM Ahmedabad, where I specialized in agribusiness during the PGDM- ABM (2009-11). The reason why I had chosen SOS  Hermann Gmeiner school over other schools in Faridabad was due to the ethos which the school had and still continues to have. The open culture and sense of collaboration among students and faculty, complete sense of freedom for a child's overall growth and the need to be rooted to one's core values were probably the greatest takeaways for me from the school.
//     `,
//     image: subhadeep,
//   },

//   {
//     id: 6,
//     title: "Karan Babbar",
//     occupation: "Product Consultant - Accenture Service Private Limited ",
//     description: `Product Consultant - Accenture Service Private Limited
//     Excellent education is one where a child's interests are nurtured and built upon. Hermann Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
//     `,
//     image: karan,
//   },
//   {
//     id: 7,
//     title: "Mr. Ajay Baisla",
//     occupation: "Municipal Councilor",
//     description: `Product Consultant - Accenture Service Private Limited
//     Excellent education is one where a child's interests are nurtured and built upon. Hermann Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
//     `,
//     image: aa,
//   },
//   {
//     id: 8,
//     title: "Dr.Rajeev Arora",
//     occupation: "Specialised Dentist and Senior lecturer.",
//     description: `Specialised Dentist andSenior lecturer at Manav Rachna Dental College. Served as Hony. Secratary  of Indian Dental Association Faridabad in 2012  & 2013 and as President in 2015.
//     `,
//     image: a,
//   },
//   {
//     id: 9,
//     title: "Anchal Gulati",
//     occupation: "DD News Anchor",
//     image: b,
//   },
//   {
//     id: 10,
//     title: "Jeevant",
//     occupation: "Professional Photographer",
//     image: c,
//   },
//   {
//     id: 11,
//     title: "Bhushan Sachdeva",
//     occupation: "Chartered Accountant",
//     image: d,
//   },
//   {
//     id: 12,
//     title: "Devender Chaudhary",
//     occupation: "Co convener urban local body",
//     image: e,
//   },
// ];
export const admissions_data = [
  {
    id: 1,
    title: "Admission Criteria",
    description: `Admission to the school is made without any distinction of religion, race, caste, place of birth, etc. As regards reservations for SC/ST etc. students, it is governed by the Education Act read, in accordance with the rules on the subject as applicable in the state, organized before each technical examination. Admission is granted as and when vacancies are available in any class. `,
    image: false,
  },
  {
    id: 2,
    title: "",
    description: `Admission forms are available in the school office from the 15th March depending upon the vacancy in different classes. All admissions close by 31st July. Transfer cases from other states can be considered against vacancy even during the running session. For admission in class IX, the entrance exam will be of 2.5 hours in the subjects of English, Mathematics, and Science.
    `,
    image: false,
  },
  {
    id: 3,
    title: "",
    description: `For admission in a class, XI counseling will be conducted for  Hermann Gmeiner school students, and other students' entrance tests will be conducted in the subject offered, subject to the availability of vacancies. Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.
    `,
    image: false,
  },
  {
    id: 4,
    title: "",
    description: `Transfer cases from other states can be considered against vacancy even during the running session. For admission in class IX, the entrance exam will be of 2.5 hours in the subjects of English, Mathematics, and Science.
    For admission in a class, XI counseling will be conducted for  Hermann Gmeiner school students, and other students' entrance tests will be conducted in the subject offered, subject to the availability of vacancies.`,

    image: false,
  },
  {
    id: 5,
    title: "",
    description: `Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.`,
    image: false,
  },
  {
    id: 6,
    title: "",
    description: `Admission to Nursery- Students must complete 3+ years of age as of April 1, of the academic year.
    Admission to Class 1- Students must complete 5+ years of age as on April 1, of the academic year.
    Admission to LKG- 30% of the seats in class LKG is reserved for BPL card holders/ EWS (Economically weaker section).
    Admission in classes PREP to VIII will be done on a ‘first come first served’ basis. The Principal's or Admission Committee’s decision will be final and binding with regard to all admissions.`,
    image: false,
  },
  {
    id: 7,
    title: "",
    description: `Those seeking admission should produce the following:`,
    image: false,
    features: [
      "Birth certificate",
      "School leaving certificate",
      "Progress card of the previous class",
      "EWS/BPL card if applicable",
      "Address Proof",
      "Character Certificate",
    ],
  },
  {
    id: 10,
    title: "Mode of Payment of Fees",
    description: `Each parent will be provided with a fee Booklet containing Challan for deposit of fees at the school counter. The fee will be paid monthly within 10 days of the beginning of the month at the UCO bank counter, opposite sector 28, Mathura Road. Parents must mention the student's name, admission number, and class with a section at the back of the cheque. In case the fees are not deposited by the due date the same can be paid at the school counter in cash only.`,
    image: false,
  },
  {
    id: 11,
    title: "",
    description: `The fee without late fee is payable till the 21st of the first month at the school fee counter. In case the cheque is dishonored, the payment of Rs 200/- shall be charged extra from the parents. The due fee will have to be paid as long as the pupil’s name is on the rolls.`,
    image: false,
  },
  {
    id: 12,
    title: "",
    description: `Only those pupils who have paid all school dues are permitted to appear for the promotion examination. Parents must clear all fees dues by 31st January to avoid any inconvenience. Admission and Annual Fees will not be refunded if a pupil is withdrawn from the school. Security deposit will be refunded only on production of the receipt copy of the Depositor’s portion of the Fee Challan.`,
    image: false,
  },
  {
    id: 13,
    title: "",
    description: `Students' admission numbers should be written in legible handwriting at the time of depositing the fees. Fees lost on account of the wrong entry of name or admission number shall be the responsibility of the parent and in such a case the student's name shall fall in the defaulter list.`,
    image: false,
  },
];
export const facilities_data = [
  {
    id: 1,
    title: "Spacious Classrooms",
    description: `The School can boast spacious, well-ventilated, and excellently furnished classrooms to make the students learning comfortable and enjoyable. Individual tables and chairs are provided to students to make them independent and self-reliant. These specially designed tables and chairs can be brought together very easily to form circles and semicircles while the lesson is in progress to have group discussions and activities.`,
    image: [spacious_classroom],
  },
  {
    id: 2,
    title: "",
    description: `To avoid carrying heavy school bags,  a special storage facility is provided for every student in the classroom. In all classrooms, display boards are provided to display creative chart works, drawings and paintings, and craftworks. The school has the best infrastructure catering for today’s learning environment – like smart interactive classrooms with ICR facilities and the use of smart board technologies for conducive learning. This facilitates comprehensive learning and makes it enjoyable and an interesting process of learning.
    `,
    image: [spacious_classroom_2],
  },
  {
    id: 3,
    title: "STEM Lab",
    description: `STEM education experiences are made available in a variety of settings by the school as a way of fostering a diverse STEM workforce. Educators are focused on improving science and mathematics instructions employing several approaches to K-12 STEM education. Students also were given opportunities to spend time with professionals in STEM fields. 
    `,
    image: [stem_lab],
  },
  {
    id: 4,
    title: "",
    description: `STEM is an abbreviation that stands for 4 closely related subjects that are Science, Technology, Engineering and Mathematics. These four theoretical and practical subjects are taught together under STEM education and starting from basics to giving precise knowledge of the subjects, professional guidelines are available at the school’s STEM lab. The students are taught by giving them real-life world problems and they are to solve these problems by theoretical and practical approach. 
    `,
    image: [stem_lab_2],
  },
  {
    id: 5,
    title: "Computer Lab",
    description: `The school has a highly sophisticated state-of-the-art computer lab. It is equipped with a computing facility with i3 computers connected through the Local Area Network as well as Wifi. The high-speed internet connectivity of 11 Mbps (24*7) to access vast intellectual resources. These facilities are an integral part of the curriculum.
    `,
    image: [computer_lab_1],
  },
  {
    id: 6,
    title: "",
    description: `Students are expected to use computers in their day-to-day study to meet the challenges of modern learning and to keep pace with global scenarios. Students are provided training on SAP, Tally ERP 9, MS Office-2016, and the Internet. The Institute website has been dynamically designed to update the latest development in the institute on a day to day basis.
    `,
    image: [computer_lab_2],
  },
  {
    id: 7,
    title: "Laboratories",
    description: `A scientific temperament is nurtured through fully equipped and suitably designed laboratories for Physics, Chemistry, and Biology. Students are encouraged to use the facilities for enhancing their innovative and scientific skills. It also provides an open environment for students to experiment and do research work. All these labs are provided with lecture/ seminar areas too. The three divisions of the science department ensure that students do real experiments.
    `,
    image: [laboratories],
  },
  {
    id: 8,
    title: "",
    description: `Our science labs are not just a rich storehouse of costly equipment and the latest gadgets on display. They are workshops of practical experiments going far beyond the limits of the prescribed syllabus. The teachers at the physics, chemistry, and biology labs challenge them with exciting projects, giving them a strenuous time clashing with known principles and existing data. This is a place where the divine curiosity of students is at work.
    `,
    image: [laboratories_2],
  },
  {
    id: 9,
    title: "Home Science Laboratory",
    description: `Home Science is a very important subject in gaining knowledge to meet the challenge of day-to-day life with confidence. The school has a well-equipped Home-Science Lab where students are acquainted with hands-on knowledge and skills. The teacher conducts different activities in the lab like cooking, stitching, embroidery, knitting, and home management. The home science students can learn Diet Planning for regular and therapeutic needs, Floor decoration, Tie & Die, Flower Arrangement, Block Printing, Samples of different Stitches, Cooking of different recipes, and Table setting.
    `,
    image: [home_science],
  },
  {
    id: 10,
    title: "",
    description: `The home science teacher demonstrates various recipes on bakery, salads, Indian, Chinese, Mexican, and Italian cuisines. The students learn the art of cooking and hone their culinary skills by using gadgets and equipment. They also make use of these lab facilities in their practice teaching, final skill-in teaching, and craft exam.
    `,
    image: [home_science_2],
  },
  {
    id: 11,
    title: "Art & Craft Room (The Heart of Creativity)",
    description: `The Art Room fosters the creative and intellectual development of the students through quality visual arts programs, facilitated by the arts faculty. The art room drives each child to be curious and original in their work while they study a variety of Art forms such as 2D, 3D, and installations by exploring mediums like acrylic, charcoal, water, dry pastels, oil pastels, pencil coloring, clay, wires, papier mache, etc.
    `,
    image: [arts],
  },
  {
    id: 12,
    title: "",
    description: `The periods in the Art Room offer each child a learning experience that teaches him or her to be a risk-taker, thinker, inquirer, and a good communicator in a safe environment. The Art teachers help the students in drawing, painting, crafts work, best out of waste, and clay modeling. Providing the students with experiences in art, craft and design enables them to reflect critically on their own and others’ work. We aim that they learn to think and act as artists, working creatively and intelligently.
    `,
    image: [arts_2],
  },
  {
    id: 13,
    title: "Library",
    description: `The serene ambiance inspires a love for reading. Information at the click of a fingertip invites young users to delve into the magical world of words and the wealth of research material available. The school has a well-equipped library.  The eclectic collection that export constitutes the Library currently includes over 10,000 books. The Library resources comprise Encyclopedias, Dictionaries on various subjects, general collections, valuable sourcebooks, critical works as well as specific skills-related books on Personality Development, Time Management, and Communication Skills. 

    `,
    image: [library],
  },
  {
    id: 14,
    title: "",
    description: `The Library subscribes to research e-journals, besides subscribing to daily newspapers, magazines, and journals. The Digital library provides access to information not just to widen the horizons of the users' knowledge but also to support a host of their learning activities that range from book learning and newspaper reading to using e-journals and reading e-books.
    `,
    image: [library_2],
  },
  {
    id: 15,
    title: "Cultural Center (Music & Dance Room)",
    description: `This is the place where young musicians start their journey to become great composers & where the children unwind and learn the aesthetics of movement. Classical Dance (Indian & Western), Modern Dance (Indian & Western), Choreography,  Direction,  Vocal  Music (Indian and Western), Instrumental Music (Indian and Western) are all interwoven into the fabric of our educational experience.`,
    image: [cultural],
  },
  {
    id: 16,
    title: "",
    description: `Dance is an ideal means of communication to express what is too deep, too fine for words. Hermann Gmeiner School’s students learn to choreograph and are conversant with various Indian and Western dance forms like Indian Classical, Jazz, Hip Hop, Salsa and these are some of the most eagerly awaited moments for self-expression and the students can be seen enjoying to the hilt.`,
    image: [cultural_2],
  },
  {
    id: 17,
    title: "",
    description: `Lessons in Indian and Western music generate rich cross-cultural exposure and sensitize the students to this fine art. The Department of Music and Dance is committed to excellence, innovation, learning in action, and entrepreneurship. Our mission is to bridge dance practice and scholarship and to foster interdisciplinary inquiry.`,
    image: [cultural_3],
  },
  {
    id: 18,
    title: "",
    description: `As a trained faculty, we bring a challenging range of perspectives to our teaching and research, and continually re-examine our curriculum to prepare both young and mature aspirants for careers in the ever-evolving field of Performing Arts.`,
    image: [cultural_4],
  },
];

export const results_data = [
  {
    title: "Toppers: X",
    description_image: [result_10, result_10_1, result_10_2, result_10_3],
    custom_class: "custom-img",
  },
  {
    title: "Toppers: XII",
    description_image: [result_12_1, result_12_2, result_12_3],
    custom_class: "custom-img",
  },
];

export const about_data = [
  {
    title: "About Us",
    description:
      "Hermann Gmeiner School, Faridabad was founded in 1986 by Shri J.N Kaul who was the then President of SOS Children’s Village of India. It is one of the oldest schools in Faridabad with a bunch of exceptionally talented students and alumni. It is a co-educational school with classes from Nursery to XII (Medical, Non-Medical, Commerce, and Humanities).",
  },
  {
    title: "",
    description:
      "The school has world class infrastructure that makes it comfortable for the students to learn and grow. The faculty leaves no stone unturned to look after the development of the students. The alumini’s experiences speak for the successful careers the students established here.",
  },
  {
    title: "Our Values",
    description:
      "Hermann Gmeiner was a man of principles and ethics. His love and compassion for children is what led him to do the social deed of education and establish SOS Children’s Village Association in 1949. Today, these institutions are active in almost 135 countries, spreading its love and knowledge.",
  },
  {
    title: "",
    description:
      "Our value and virtue is what we strive for and work our very best for all our students to learn and adapt the goodness all over. We teach our children how to excel in not only academics but every field out there and how to make a name and position for self. We teach them how to handle life’s hardships along with book and physical knowledge.",
  },
];
export const nep_data = [
  {
    id: 1,
    icon: <FaSchool />,
    description:
      "Hermann Gmeiner School pedagogy strives to make the schooling process more insightful. It has expanded the horizon of learning and made it more interesting, and motivating. The aim is to focus on the all-round development of the students rather than focusing only on the theoretical part. This makes them ready to face the outer world.",
    extra_description: ["NEP"],
  },
  {
    id: 2,
    title: "Our Curriculum aims to",
    description: "",
    features: [
      "Encourage students to think critically and solve problems through project-based learning",
      "Seamlessly integrate art into the curriculum to enhance the appreciation for aesthetic visual and performing arts.",
      "Foster holistic development through sports, i.e. Health and Physical Education.",
      "Offer activities for curricular and co-curricular enrichment via clubs and work education.",
      " Engage students in addressing real-world problems and issues important to humanity to lead change.",
      " Provide differentiated teaching-learning activities to personalize learning.",
    ],
  },
  {
    id: 3,
    title: "Key Features of the Curriculum",
    description: "",
    features: [
      "Individualized attention – low student-teacher ratio",
      "Stimulating learning environment",
      "Emphasis on all-round development and co-curricular activities",
      "Integration of ICT and Innovation in the Curriculum",
      "Parental Engagement Programmes",
      " Eco-Literacy Curriculum",
      "360-degree assessment approach",
      "Skill-based subjects Imbibing values & social skills",
    ],
  },
];
export const life_curriculum_data = [
  {
    id: 1,
    description: `Adapting the Life Skill Curriculum in the daily routine of every child at the primary stage is a very crucial part of our education. The basic hygiene importance like handwashing and daily bathing, and the value of chivalry and gratitude towards one another must be taught at their impressionable age and fortified over the years. The three components of the Life Skill Curriculum; the individual life-skill, the social skill, and the effective decision making skills are some of the eminent topics of our syllabus. To not only make our students academic geniuses, but to also refine their social and personal skills is what we aim for.`,
    description_image: [life_skill_1],
  },
  {
    id: 2,
    title: "",
    description: `We believe in testing and creating challenges for our students, so they can break their barriers and overcome their judgemental and stereotypical fears. To educate all of them about daily chores, irrespective of the gender, makes the batchmates comfortable and more open to these topics. The ten core Life Skills adopted by our education system are - 1. Self-awareness, 2. Empathy, 3. Critical Thinking, 4. Creative Thinking, 5. Decision Making, 6. Problem Solving, 7. Effective Communication, 8. Interpersonal relationship, 9. Coping with stress, 10. Coping with Emotion.
    `,
    // description_image: [life_skill_2],
  },
];
export const examination_data = [
  {
    id: 1,
    title: "Assesment",
    description: `At specific periods of time, we conduct secure and stable examinations for the students of all grades to not only test their capabilities but also to help them gain self-confidence. The children are marked on the basis of their academic and co-curricular results along with their year-long behaviour and activities.`,
  },
];
export const skill_vocational_data = [
  {
    extra_description: [
      `Vocational subjects include more hands-on training and less of the traditional academic learning. We at  Hermann Gmeiner school focus on integrating art with the curriculum for all the students to appreciate the aesthetics and visual performing arts. Practical or first-hand knowledge about the market or trade is a very essential part of the vocational training that we provide to the learners.`,
      `We always prioritize our students and for that we offer differentiated teaching-learning activities as well as personalized learning. All the varied Skill and Vocational subjects are included and taught at our institution. A team of dignified and intellectual teachers is always on the stand to help the students master the subjects.`,
      `Emulating the skill-based model of education as per NEP 2020 and CBSE guidance, we have also introduced the skill subjects at the secondary level. Choosing any one Skill subject at this level can help the child to pursue what truly interests or pleases him or her. This liberty promotes a sense of self-esteem in accepting one’s talents and strengths.`,
    ],
  },
  {
    title: "Classes VI-VIII",
    features: ["Financial Literacy", "Handicraft", "Dance", "Yoga"],
  },
  {
    title: "Classes  IX-X",
    features: [
      "Life skill",
      "Handicraft",
      "Dance",
      "Music",
      "Yoga",
      "Paper Mache",
    ],
  },
  {
    title: "Classes XI-XII",
    features: ["Marketing", "Music", "Yoga", "Life Skill"],
  },
];
export const co_curricular_data = [];
export const house_clubs_data = [
  {
    id: 2,
    title: "Sports Club",
    description: `The sports club encourages students to be physically and mentally fit and to live a healthy lifestyle. Sports meets are held by the club, which include both inter-house and inter-school tournaments. It aids in the process of increasing students' attentiveness and confidence levels. Students engage in a variety of physical exercises to keep them energetic and nimble. At school, International Yoga Day was observed online. There were Facebook live seminars on fitness and exercise. The gaming session is an important and integral element of the educational day. Sports clubs also aim to increase student participation in a variety of sports.`,
    image: [sports_club],
    icon: [sports_club_icon],
  },

  {
    id: 4,
    title: "Health Club",
    description: ` 
    The health club is large, airy, and well-equipped with all of the latest amenities necessary for students to maintain a healthy lifestyle. Instructors assist in the process by developing specialised programmes for the youngsters based on their specific needs. The educators concentrate on the nutritional aspects of pupils' health. They instil healthy eating habits and stress the significance of a well-balanced diet in order to live a long and healthy life.`,
    image: [health_1],
    icon: [health_club_icon],
  },

  {
    id: 5,
    title: "Social Impact Club",
    description: `The Social Impact Club promotes self- and community-awareness. It raises awareness of societal needs and fosters a sense of citizenship among students. The organisation holds regular activities to help students have a better understanding of and involvement in their communities. It instils compassion, proactiveness, and resilience in students.`,
    image: [social_impact],
    icon: [social_impact_club_icon],
  },

  // {
  //   id: 6,
  //   title: "Communication Club",
  //   description: `Communication club is a group of students that strives to improve their communication and presentation skills of the students. It helps to improve the language skills of the students and make them proficient in speaking. This is done by a series of fun and interactive games through which students learn about expressions, confidence and elocution.`,
  //   image: [budding_2],
  // },
  {
    id: 6,
    title: "Gender Sensitivity Club",
    description: `This club aims to educate students about different genders and their roles in society. It promotes understanding of diverse features of all genders while also instilling respect and equality for all. It enables children to comprehend how gender roles influence people's personal and professional lives. It aids in comprehending how we can work to reestablish these gender roles in order to create a more united society.`,
    image: [gender_img],
    icon: [gender],
  },
  {
    id: 3,
    title: "Environment Club",
    description: `
    The environment club hosts regular workshops and events such as tree planting, lectures, and teaching youngsters about the importance of the environment and how we can all work together to improve it. The environment club has written essays on environmental issues and how we can all help to save it. The school's club organises anti-tobacco, anti-cracker, and anti-cleanliness campaigns.`,
    image: [environment_club],
    icon: [environment_club_icon],
  },
  {
    id: 7,
    title: "Expressions Club",
    description: `The Expression club focuses on the development of talents in nonverbal expression. The programme focuses on improving kinesthetic body control abilities and forming a connection with the music that goes along with it. It improves kids' focus and concentration. The club encourages children to participate and allows students of all abilities to demonstrate their abilities. A large expression room, supervised by competent experts, offers music, dance, and theatre lessons in order to instil the rich cultural traditional values and skills.`,
    image: [expression_club],
    icon: [expression],
  },
  {
    id: 8,
    title: "Budding Authors’ Club",
    description: ` Hermann Gmeiner school is addressing the need for all-round development of its students by incorporating a variety of creative activity clubs into each child's weekly schedule. The 'Budding Authors' Club is one such group. The Club's Budding Authors' Programme aims to develop the writing abilities of its members. The Budding Authors' Program is based on the premise of instilling in growing children the positive habits of reading and writing.
    The young learners teeming with energy and curiosity are provided with a creative anchor. The pursuit of reading and writing allows the harnessing and channelizing in the right direction, of this vast reservoir of inquisitiveness. A seasoned writer and trainer develops deep bonds of camaraderie and exerts a positive influence in the holistic development of a child, the effect of which is manifested in every aspect of the child’s life.
    `,
    image: [budding_2],
    icon: [gender],
  },
  {
    id: 9,
    title: "House System",
    description:
      " Hermann Gmeiner school Bhimtal's House system is designed to foster teamwork and a sense of belonging to the school. This also allows the school's senior students to provide care and guidance to their younger schoolmates. The House structure provides excellent leadership and peer group possibilities for students. In addition, the House structure at  Hermann Gmeiner school Bhimtal fosters a healthy competitive spirit among students from various Houses. It's a fantastic way to bring out students' hidden talents.",
    image: [house_system],
  },
  // {
  //   id: 13,
  //   title: "School Disaster Management Committee (SDMC)",
  //   description: `The school disaster management committee makes students aware of the various hazards and disasters. It conducts online and offline sessions where the students are shown the videos like fire safety, earthquake readiness, etc. It conducts mock drills in school so that the children get hands-on experience of dealing with such situations.
  //   `,
  //   image: [sdmc],
  // },
  // {
  //   id: 14,
  //   title: "",
  //   description: ` It also includes making students empathetic towards their classmates, making them understand the varying needs of their fellow students and people. It imparts understanding on concepts like the buddy system, children with special needs (orthopedic, visual, or learning). With this, children become compassionate and helpful towards their classmates and people in need.`,
  //   image: [sdmc_2],
  // },
];

export const houses_school = [
  {
    id: 1,
    title: "Subhash House",
    description: `Subhash House gets its name from Subhash Chandra Bose. He demonstrated the "Hind Fauz" path. Open spaces, independence, intuition, imagination, inspiration, spiritual enlightenment, and sensitivity are all associated with the blue colour of this house, which represents both the sky and the sea. We, the Subhasians, are always humble, hardworking, and sincere in our efforts. The main objective of this house is: "Winners do not do different things, but they do things differently,`,
    color: "#0440B6D6",
    houseImg: [blue],
  },
  {
    id: 18,
    title: "Nehru House",
    description: `The house is named after Pandit Jawahar Lal Nehru, a notable political leader who is well-liked by youngsters and is known as Chacha Nehru. This house is represented by the colour orange as it is associated with meanings of joy, warmth, sunshine, enthusiasm, creativity, success, encouragement, determination, happiness, fun, and balance. The major goal of this house is to work together and remain upright in all conditions.`,
    color: "orange",
    houseImg: [yellow],
  },
  {
    id: 19,
    title: "Azad House",
    description: `Don't see others doing better than you. Beat your records every day, because success is a fight between you and yourself." - Chandrashekhar Azad During India's independence movement, Azad was an Indian revolutionary who organised and commanded a group of militant youth. Whereas, this house is represented by the colour red, which depicts patronage and is associated with Azadian bravery.`,
    color: "#FF1212",
    houseImg: [red],
  },
  {
    id: 20,
    title: "Gandhi House",
    description: `Gandhi House is named after Mahatma Gandhi, the Father of the Nation, who rediscovered the path of Satyagraha and inspired the entire world. The colour GREEN is used to represent this house, as it is a fresh symbol of well-founded optimism.`,
    color: "#24EA03F5",
    houseImg: [green],
  },
];
export const student_council_data = [
  {
    id: 1,
    description: `The school’s student council is headed by the Head Girl and Head Boy along with the Vice-heads. House captains, vice-captains and house prefects represent the school houses, while the cultural, sports and discipline committees have separate representatives.
 
    `,
    subdesc: `Students are encouraged to take on responsibility and fulfil their roles with sincerity and passion. The teachers and house in-charge guide the students to inculcate leadership qualities in them.`,
    description_image: [student_council_2],
  },
  {
    id: 2,
    title: "",
    description: `
`,
  },
];
export const disclosure_data_general = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "NAME OF THE SCHOOL",
    description: "Hermann Gmeiner School, Bhimtal",
  },
  {
    id: 3,
    title: "2",
    name: "AFFILIATION NO (IF APPLICABLE)",
    description: "3530031",
  },
  {
    id: 4,
    title: "3",
    name: "SCHOOL CODE (IF APPLICABLE)",
    description: "81033",
  },
  {
    id: 5,
    title: "4",
    name: "COMPLETE ADDRESS WITH PIN CODE",
    description:
      "Hermann Gmeiner School, Bhimtal Distt. Nainital, Uttarakhand, India-263136",
  },
  {
    id: 6,
    title: "5",
    name: "PRINCIPAL NAME",
    description: "Mrs. Mahima Bhatt",
  },
  {
    id: 7,
    title: "6",
    name: "PRINCIPAL QUALIFICATION",
    description: "M.Sc M.A. B.Ed",
  },
  {
    id: 8,
    title: "7",
    name: "SCHOOL EMAIL ID",
    description: "Principal.Bhimtal@hermann-gmeiner-edu.org",
  },
  {
    id: 9,
    title: "8",
    name: "CONTACT DETAILS",
    description: "(05942) 247073,248277",
  },
];
export const disclosure_data_documents = [
  {
    id: 1,
    title: "S. No",
    name: "DOCUMENTS/INFORMATION",
    description: "LINKS OF UPLOADED DOCUMENTS",
  },
  {
    id: 2,
    title: "1",
    name: "COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY",
    description: "",
    customcomponent: require("../src/pdf/affiliationextension.pdf"),
  },
  {
    id: 3,
    title: "2",
    name: "COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE",
    description: "",
    customcomponent: require("../src/pdf/trust.pdf"),
  },
  {
    id: 4,
    title: "3",
    name: "COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT",
    description: "",
    customcomponent: require("../src/pdf/noc.pdf"),
  },
  {
    id: 5,
    title: "4",
    name: "COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE",
    customcomponent: require("../src/pdf/rcu.pdf"),
  },
  {
    id: 6,
    title: "5",
    name: "COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE",
    customcomponent: require("../src/pdf/bsc.pdf"),
  },
  {
    id: 7,
    title: "6",
    name: "COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY",
    description: "",
    customcomponent: require("../src/pdf/firesafety.pdf"),
  },
  {
    id: 8,
    title: "7",
    name: "COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/ UPGRADATION/ EXTENSION OF AFFILIATION OR SELF CERTIFICATION BY SCHOOL",
    description: "",
    customcomponent: require("../src/pdf/deo.pdf"),
  },
  {
    id: 9,
    title: "8",
    name: "COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES",
    description: "",
    customcomponent: require("../src/pdf/water.pdf"),
  },

  {
    id: 10,
    title: "9",
    name: "SEXUAL HARASSMENT",
    description: "",
    customcomponent: require("../src/pdf/sexualHarresmnt.pdf"),
  },
  {
    id: 11,
    title: "10",
    name: "CHILD SAFE GUARD COMMITTEE",
    description: "",
    customcomponent: require("../src/docs/CHILD_SAFE_GUARD_COMMITEE 23-24.docx"),
  },
  {
    id: 12,
    title: "11",
    name: "LAND CERTIFICATE",
    description: "",
    customcomponent: require("../src/pdf/land.pdf"),
  },
  {
    id: 13,
    title: "12",
    name: "TRANSPORT CERTIFICATE",
    description: "",
    customcomponent: require("../src/pdf/transport.pdf"),
  },
  {
    id: 14,
    title: "13",
    name: "CBSE AFFILATION UPTO 2025",
    description: "",
    customcomponent: require("../src/pdf/cbse.pdf"),
  },
  {
    id: 15,
    title: "14",
    name: "PARENT TEACHER ASSOCIATION",
    description: "",
    customcomponent: require("../src/pdf/parentteacher.pdf"),
  },
];
export const disclosure_data_results = [
  {
    id: 1,
    title: "S. No",
    name: "DOCUMENTS/INFORMATION",
    description: "LINKS OF UPLOADED DOCUMENTS",
  },
  {
    id: 2,
    title: "1",
    name: "FEE STRUCTURE OF THE SCHOOL",
    description: "",
    customcomponent: require("../src/pdf/feestructure.pdf"),
  },
  {
    id: 3,
    title: "2",
    name: "ANNUAL ACADEMIC CALENDAR",
    description: "",
    customcomponent: require("../src/excel/Academic-Calendar-2024-2025.xlsx"),
  },
  {
    id: 4,
    title: "3",
    name: "LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)",
    description: "",
    customcomponent: require("../src/docs/Committee_Members_2024-25.docx"),
  },
  {
    id: 5,
    title: "4",
    name: "LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS",
    description: "",
    customcomponent: require("../src/docs/PTA.docx"),
  },
  {
    id: 6,
    title: "5",
    name: "LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY",
    description: "",
    customcomponent: require("../src/pdf/threeyearsresult.pdf"),
  },
  {
    id: 7,
    title: "6",
    name: "LIST OF HOLIDAYS",
    description: "",
    customcomponent: require("../src/excel/Holiday List-Students-2024 (1).docx"),
  },
];










export const disclosure_data_staff = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "PRINCIPAL",
    description: "Mrs. Mahima Bhatt",
  },
  {
    id: 3,
    title: "2",
    name: "TOTAL NUMBER OF TEACHERS",
    name1: "PGT",
    name2: "TGT",
    name3: "PRT",
    description: "44",
    description1: "14",
    description2: "15",
    description3: "13",
  },
  {
    id: 4,
    title: "3",
    name: "TEACHERS SECTION RATIO",
    description: "44:30",
  },
  {
    id: 5,
    title: "4",
    name: "DETAILS OF SPECIAL EDUCATOR",
    description: "01 Ms. Suman Rawat TGT",
  },
  {
    id: 6,
    title: "5",
    name: "DETAILS OF COUNSELLOR AND WELLNESS TEACHER",
    description: "01 Mr. Ashish Kaul PET",
  },
];
export const disclosure_data_results_10 = [
  {
    id: 1,
    title: "S. No",
    extra_title: "YEAR",
    name: "NO. OF REGISTERED STUDENTS",
    description: "NO. OF STUDENTS PASSED",
    extra_description: "PASS PERCENTAGE",
  },
  {
    id: 2,
    title: "1",
    extra_title: "2019",
    name: "117",
    description: "117",
    extra_description: "100%",
  },
  {
    id: 3,
    title: "2",
    extra_title: "2020",
    name: "112",
    description: "112",
    extra_description: "100%",
  },
  {
    id: 4,
    title: "3",
    extra_title: "2021",
    name: "132",
    description: "132",
    extra_description: "100%",
  },
];
export const disclosure_data_results_12 = [
  {
    id: 1,
    title: "S. No",
    extra_title: "YEAR",
    name: "NO. OF REGISTERED STUDENTS",
    description: "NO. OF STUDENTS PASSED",
    extra_description: "PASS PERCENTAGE",
  },
  {
    id: 2,
    title: "1",
    extra_title: "2021",
    name: "146",
    description: "146",
    extra_description: "100%",
  },
];
export const disclosure_data_school = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)",
    description: "169968",
  },
  {
    id: 3,
    title: "2",
    name: "NO. AND SIZE OF THE CLASS ROOMS (IN SQUARE MTR)",
    description: "40 (2.118189X 1.746577)",
  },
  {
    id: 4,
    title: "3",
    name: "NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQUARE MTR)",
    description: "03 (3.028639 X 2.04387)",
    description1: "02 (3.781154 X 2.034577)",
  },
  {
    id: 5,
    title: "4",
    name: "INTERNET FACILITY",
    description: "FIBER OPTIC",
  },
  {
    id: 6,
    title: "5",
    name: "NO. OF GIRLS TOILETS",
    description: "10",
  },
  {
    id: 7,
    title: "6",
    name: "NO. OF BOYS TOILETS",
    description: "10",
  },
  {
    id: 8,
    title: "7",
    name: "LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL",
    description: "https://www.youtube.com/channel/UCzrw1oXDjDmkcIYT8TeNMgQ",
  },
];
export const trip_data = [
  {
    id: 1,
    description: `School trips are organised for staff and boarders as a way of boosting team spirit and including some relief from the routine. With every trip, we try to improve the exposure of the students. The trips planned range from historical monuments, natural retreats and amusement centred locations. Morale boosting is another important role that trips play in students' routine.`,
    subdesc: `The last trip was organized in winter when students and staff visited Nainital. Transportation was made available by the school administration and the trip was found fruitful by teachers and students alike.`,
    description_image: [trips_1],
  },
  {
    id: 2,
    title: "",
    description: ``,
    // description_image: [trippara2],
  },
];
export const ncc_scouts_guides_data = [
  {
    id: 1,
    title: "NCC, Band, Scouts & Guides",
    description: `To inculcate discipline and leadership qualities, a large number of students are being trained under the NCC & Scout and Guides for making them capable, sensitized to work for the betterment of society and for enhancing their lifestyle and leadership qualities.`,
  },
  {
    id: 2,
    title: "NCC",
    description: `The school has upgraded NCC from the junior to senior wings for both boys and girls. Imbibing discipline and leadership qualities in students is one of the main motives behind the admission of a large number of students as NCC cadets. The cadets are mentally and physically trained to become productive citizens of the nation.`,
    description_image: [ncc_1],
  },
  {
    id: 3,
    title: "School band",
    description: `The school always encourages the students to participate in all the activities they find comfort and peace in. The school band not only represents our school’s unity and discipline as a group, but also is proven to be a fun activity for all the interested musicians of our academy. The rhythm and rehearsal of the band brings a serenity in the school campus and also creates an environment filled with joy, fun and music. School band warmly accepts all the interested students and helps them polish and increase their skills and passion for music.    `,
    description_image: [school_band_1],
  },
  {
    id: 4,
    title: "Scouts and Guides",
    description: `There are junior division troops of NCC for boys as well as for girls, grouped into Scouts and Guide respectively. The cadets participate in the regular drill, training camps, adventure activities, community awareness programs, etc. to become conscientious and contributing human beings who will make the world a better place. These regular drills and training equip them for society.
    It aims to create a community of organised, sincere and disciplined youth who are rightful and true to their responsibilities. Scouts and Guide training aims to develop selfless attitude among students, grit, determination and the desire to achieve. It builds a winning mindset in the minds of youth to help them achieve whatever they aim for.`,
    description_image: [scouts_1],
  },
];
export const transportation_data = [
  {
    title: "",
    description: `Scouts is a voluntarily educational movement for people, mostly teenagers, which is open to all without discrimination of gender, caste, race, or any religion. The whole idea behind Scouts is to contribute to the development of youngsters in gaining their full physical, mental, emotional, social, and intellectual ability as individual human beings, as responsible Indians, and as members of their local, national, and international communities.
    A guide is someone who leads humans towards their goals. A guide helps the students to grow mentally, physically, socially, and emotionally. They help them to make informed decisions and bring in the quality of discipline in them. The children are taught the rules and regulations which makes them more confident and sincere.`,
    description_image: [bus_1],
    custom_class: "custom-img",
  },
];
export const award_text_data = [
  {
    title: "",
    description: [
      " Hermann Gmeiner school proudly highlights its achievements and presents the best of its students, whether they are excelling academically, athletically or in service to the community. We hope to inspire more students to excel, their achievements ultimately make the school become stronger.",
      "By highlighting student achievements, we aim to eliminate self-doubt and build confidence in our students that hard work is important and is making an impact. In turn, this confidence can carry over to other facets of their young lives, including making smart choices when faced with several challenges as they grow up.",
    ],
  },

  {
    features: [
      "Global School Award for Innovation in Pedagogical Practices in 2020",
      "Best Teaching Excellence Award by International School Award held at Dubai in 2018",
      "Indian School Award in 2018",
      "National School Award 2018",
      "Global Sustainable School Award 2017 as a recognition of its efforts towards community service and environment",
    ],
  },
];
// the long list of awards such as
//stand testimony to the fact that the school never rests on its laurels.

export const awards_data = [
  {
    title: "",
    image: award_1,
  },
  {
    title: "",
    image: award_2,
  },
  {
    title: "",
    image: award_3,
  },
  {
    title: "",
    image: award_4,
  },
  {
    title: "",
    image: award_5,
  },
  {
    title: "",
    image: award_6,
  },
];
export const news_text_data = [
  {
    description:
      "Hermann Gmeiner School has been incessantly creating fervor in society and has been garnering Media coverage, getting its message across to a wider audience and establishing itself as a credible school and its activities as notable.Reputed newspapers have on several ocaasions, published the events and happenings at  Hermann Gmeiner school along with the laurels and accolades received. We humbly acknowledge this recognition and strive to be a responsible school and be dedicated towards the community welfare.",
  },
];
export const news_data = [
  {
    title: "",
    image: media1,
  },
  {
    title: "",
    image: media2,
  },
  {
    title: "",
    image: media3,
  },
  {
    title: "",
    image: media4,
  },
  {
    title: "",
    image: media5,
  },
  {
    title: "",
    image: media6,
  },
  {
    title: "",
    image: media7,
  },
  {
    title: "",
    image: media8,
  },
  {
    title: "",
    image: media9,
  },
  {
    title: "",
    image: media10,
  },
  {
    title: "",
    image: media11,
  },
  {
    title: "",
    image: media12,
  },
  {
    title: "",
    image: media13,
  },
  {
    title: "",
    image: media14,
  },
  {
    title: "",
    image: media15,
  },
  {
    title: "",
    image: media16,
  },
  {
    title: "",
    image: media17,
  },
  {
    title: "",
    image: media18,
  },
  {
    title: "",
    image: media19,
  },
  {
    title: "",
    image: media20,
  },
  {
    title: "",
    image: media21,
  },
  {
    title: "",
    image: media22,
  },
  {
    title: "",
    image: media23,
  },
  {
    title: "",
    image: media24,
  },
  {
    title: "",
    image: media25,
  },
  {
    title: "",
    image: media26,
  },
  {
    title: "",
    image: media27,
  },
  {
    title: "",
    image: media28,
  },
  {
    title: "",
    image: media29,
  },
  {
    title: "",
    image: media30,
  },
  {
    title: "",
    image: media31,
  },
  {
    title: "",
    image: media32,
  },
  {
    title: "",
    image: media33,
  },
  {
    title: "",
    image: media34,
  },
  {
    title: "",
    image: media35,
  },
];
export const alumni_text_data = [
  {
    id: 1,
    title: "Lt. Col. Shreem Kapil",
    for: "alumni",
    occupation: "Indian Army",
    description: `Shreem Kapil was born and brought up in Bowali, a small town in Uttrakhand. I belonged to a middle-class family but my parents always encouraged me to do well in life. Initially, I studied in a Local school at Bowali and in 1996 I took admission to Hermann Gmeiner School, one of the most reputed Schools in Bhimtal. Hermann Gmeiner School has played a vital role in shaping my life. The school has always been providing the best personality development education to the students. The teachers helped me to enhance my personality. I think one's personality is equally important as one's education.
    </br> 
    Elevated personality never pushes you back, instead, it helps you to move forward and outshine in the crowd. But for having a good personality, being educated is important. This is what Hermann Gmeiner school Bhimtal is doing, i.e providing quality education and imbibing moral values. My school has played an important role in moulding my life by facilitating all-round development. I cleared my CDS Exam in the year 2004 and joined the Indian Military Academy. After undergoing strenuous training at the academy, I passed out as Lt. and joined Gorkha Rifles, one of the most coveted regiments of the Indian Army. I wish good luck to all the Students, Teachers, and Management of  Hermann Gmeiner school, Bhimtal.
      `,
    // extra_description: ["Elevated personality never pushes you back, instead, it helps you to move forward and outshine in the crowd. But for having a good personality, being educated is important. This is what Hermann Gmeiner school Bhimtal is doing, i.e providing quality education and imbibing moral values. My school has played an important role in moulding my life by facilitating all-round development. I cleared my CDS Exam in the year 2004 and joined the Indian Military Academy. After undergoing strenuous training at the academy, I passed out as Lt. and joined Gorkha Rifles, one of the most coveted regiments of the Indian Army. I wish good luck to all the Students, Teachers, and Management of  Hermann Gmeiner school, Bhimtal."],
    image: rohan,
  },
  {
    id: 2,
    title: "Monika Yadav",
    for: "alumni",
    occupation: "Deputy Superintendent Of Police, UP",
    description: `SMs. Monika Yadav, Deputy Superintendent Of Police, UP sees how Hermann Gmeiner School has impacted her life and why she stays connected. In the story of one's life, how and where we end depends a great deal on how and where we begin. And since life can only be understood backward, it is such a happy feeling to look back at the old school days and be transported to what was (and still remains) a utopian world for all of us at the  Hermann Gmeiner school.
    </br> 
    The life lived there, the experiences from the soil of that beautiful kingdom, and the learnings we had from the wonderful teachers who were such support even in later life, has set an unbeatable standard to strive for in our lives, even today. We all go through our struggles, but the approach acquired towards life's challenges and the overall personality that is shaped up in the school days is hard to change in any academic and professional institution.
    </br> 
    The whole ambiance of that place is so positive and healthy. The relationships and the friendships forged there have stood the tests of time and have come out stronger after passing out from school. In fact I remember how my friends and teachers, just like my family, stood by me in my struggle phase and cheered for me whenever I accomplished any feat in my later academic and professional life. That was  Hermann Gmeiner school! After completing my schooling, I did my B.Tech in Mechanical Engineering from GB Pant University. Thereafter, worked in Mahindra & Mahindra Auto Sector as Assistant Manager and then in PNB as Assistant Manager before qualifying as Deputy Superintendent of Police in the state of Uttar Pradesh.
    </br> 
    In the services I am today, we go through innumerable and detailed interrogations. The whole life of a person (victim, accused, family members etc) is laid open in front of us. When we draw a chart of what leads to what, I thank God for giving me such a healthy and happy childhood, such supportive parents, good schooling, great teachers and wonderful friends, so as to forge a positive and constructive outlook towards life. Though it was written in a different context, but, in free times, whenever I listen to or read "Gar Firdaus bar-rue zamin ast, hami asto, hamin asto, hamin ast" (If there is a heaven on earth, it’s here, it’s here..), I am invariably reminded of Bhimtal and  Hermann Gmeiner school! Pure Serenity to even think of the school days !
    `,
    image: karandev,
  },
  {
    id: 3,
    title: "Col. Kamlesh Bisht",
    for: "alumni",
    occupation: "Col. Kamlesh Bisht",
    description: `It gives me immense pride and with a sense of heartfelt gratitude for my Alma Mater today I try to recollect those precious moments and try to Jot down the same. If someone asks me today, to quantify the small little achievements in my journey of life so far, indeed fifty percent share will go to  Hermann Gmeiner school, needless to say, the remaining is unquantifiable but certainly, I will dedicate it to the Almighty God, my parents, and of course the blessings of my well-wishers. 
    </br> 
    The interviewing Old man, Dr. Rangpal, bearing a graceful and mesmerising personality, had the ability to X-ray anyone. After 45 mins or so, the interview was going on well until the bouncer was thrown at me when he asked "What do you want to do in the future?", I had no ready-made answer for it. A boy from a village who was not sure of tomorrow at that time never used to dare to dream far off! However, the reply came out instantaneously which was probably in my subconscious mind and I immediately answered "A soldier or a Farmer".
    </br> 
    Probably he also never expected this as an answer, to clarify his doubt he further asked what will decide if you have to become a soldier or a farmer ?. "If I get a chance of better education in  Hermann Gmeiner school I would have a chance to become an army officer else the land in the hill automatically qualifies me to pursue hill farming even in the future." was my answer. Further, I added that the decision by The principal is actually the deciding factor surely.
    </br> 
    He was indeed impressed with my answer, and right away he offered me a free Boarding facility inclusive of a fully subsidised fee in  Hermann Gmeiner school. So, Here started the Journey In  Hermann Gmeiner school. At that time we were the pioneers of  Hermann Gmeiner school. I find myself lucky enough to have been taught by legendary N. Singh sir, Bhanu Pratap and many more fascinating teachers. Finally, I passed out of  Hermann Gmeiner school in the year 1993 after my senior secondary exams.
    </br> 
    After eleven years of the date of interview, My Interviewer's decision proved right when in the year 1998 I became the first officer from  Hermann Gmeiner school who studied from class 8th onwards to get selected and finally commissioned in the Indian Army in 1999. The commissioning year saw the Kargil war and I was directly inducted in the battle zone. Which I feel really proud of to date. Later the foundation was laid at  Hermann Gmeiner school and by God's grace I qualified for IIT Delhi from the Army and did M.Tech. in electrical engineering. Till that time no other student of  Hermann Gmeiner school was so lucky to have a PG degree from IIT.
    </br> 
    In a nutshell, what I am today, what I achieved is all due to my Alma mater. I feel proud when I see  Hermann Gmeiner school guys doing good.  Hermann Gmeiner school earned the name. There have been many ups and downs in the history of  Hermann Gmeiner school as well. I have been actively interacting and in various forums voicing my opinion for the wonderful school. It is so satisfying to see the flag of  Hermann Gmeiner school fluttering High today. So many students have made the school proud by their success stories and the most satisfying thing is to see it again in the safe hands of KD sir as principal.
    `,

    image: anjan,
  },
  {
    id: 4,
    title: "Dr. Ankita Yadav",
    for: "alumni",
    occupation: "Food Safety Officer",
    description: `Dr. Ankita Yadav, Food Safety Officer, Food Safety and Drug Administration, Uttar Pradesh,  Hermann Gmeiner school BATCH-2005. No wonder, Bhimtal is a paradise on earth.  Hermann Gmeiner school, its heavenly kingdom, and the teachers are its deity. I am blessed by the guidance of teachers which was a stepping stone of success for me. The education and the support offered is a feather on the cap. Although I have completed my Ph.D. in Human Nutrition, from Pantnagar the best learning of my life is in the  Hermann Gmeiner school which laid the foundation for my career. I wish all the happiness and success to the students of  Hermann Gmeiner school.`,
    image: prakriti,
  },

  {
    id: 5,
    title: "Bikram Singh Jantwal",
    for: "alumni",
    occupation: "Finance Controller",
    description: `Education: M.A.(Economics), PGDCA, MBA ( Finance) Designation: Finance Controller, Transport Department, Uttarakhand Government. Selected through the first batch of Uttarakhand PCS. Student of  Hermann Gmeiner school Bhimtal from 1988 to 1995. The serene environment and the friendly learning atmosphere at  Hermann Gmeiner school Bhimtal always motivated me to do something meaningful in my life. I feel thankful to all my teachers, the staff of  Hermann Gmeiner school, and fellow students for making the time spent at  Hermann Gmeiner school a lifelong memory to be cherished.`,
    image: abhishek,
  },
  {
    id: 5,
    title: "Dr. Deepika Lohani",
    for: "alumni",
    occupation: "Eye surgeon",
    description: `As they say, the school lays the solid foundation for a bright future,  Hermann Gmeiner school Bhimtal did the same for me. I joined  Hermann Gmeiner school in 2006 in 9th Standard and passed out in 2009 and those were undoubtedly the golden days of my life. I still remember the school gates, the classroom, and the corridors and I am filled with nostalgia. I would like to thank KD sir, Chandra Amola mam for their guidance that made me a confident student to walk out the school gates.
    </br>
    From being the topper in class 10 to winning debate competition to scoring 6th Rank in UPMT would not have been possible without the guidance of teachers and the school atmosphere which made me a confident individual. Currently, I am working as an Eye surgeon in BD Pandey Hospital, Nainital. I am a proud Hermann Gmeinerite. Thank you for all these wonderful memories.
    `,
    image: subham,
  },
  {
    id: 5,
    title: "K Robindro Singh",
    for: "alumni",
    occupation: "Deputy Speaker of Manipur LA",
    description: `Because my teachers and friends believed in me! Thank you so much KD sir, for giving me an opportunity to thank my teachers at  Hermann Gmeiner school, friends, and  Hermann Gmeiner school fraternity. I thank each one of you for contributing so much to my life; wherever I go, I carry the values of  Hermann Gmeiner school with me. I thank KD Sir, Chandra Mam, Gunwant Sir, Dharmendra Sir, Savita Mam, Indu Ma’am, Saraswati Ma’am, Pokharia Sir, Vinod Joshi Sir, Dubey Sir, N. Singh Sir, Mishra Sir, Matto Sir, Matto Mam and Dilip Kaul Sir, for bestowing so much love and trust on me
    </br>
    I also thank my  Hermann Gmeiner school friends for still being my amazing support system. The two beautiful years (1997-1999), which I invested in Hermann Gmeiner, provided me enough opportunities to bloom to the fullest. I especially admired the way extracurricular activities were integrated with the academic curriculum, for instance, regular debates, sports carnivals, dance competitions, poem recitals, chorus singing, and drama performances were the part and parcel of everyday life at  Hermann Gmeiner school Hermann Gmeiner school. In such a short tenure of two years in  Hermann Gmeiner school Bhimtal, I got plenty of opportunities to participate in all of them, isn't it laudable? Right now,
    </br>
    I am the captain of the women’s cricket team of St Johns Aberfeldie Club at Melbourne and  Hermann Gmeiner school did a lot in infusing these leadership skills in me. Professionally, I have done my B.Tech. (Information Technology) from Dehradun Institute of Technology and worked in HCL for six years. Later, I found my passion for teaching and I have recently completed my Master of Teaching from The University of Melbourne. I would be contributing to the Education Sector of Victoria (Australia). I congratulate each one of you for being part of such an amazing institution called  Hermann Gmeiner school. Namaskar! Neelima Bhatt
    `,
    image: vivek,
  },
  {
    id: 5,
    title: "Neelima Bhatt",
    for: "alumni",
    occupation: "Cricketer",
    description: `Because my teachers and friends believed in me! Thank you so much KD sir, for giving me an opportunity to thank my teachers at  Hermann Gmeiner school, friends, and  Hermann Gmeiner school fraternity. I thank each one of you for contributing so much to my life; wherever I go, I carry the values of  Hermann Gmeiner school with me. I thank KD Sir, Chandra Mam, Gunwant Sir, Dharmendra Sir, Savita Mam, Indu Ma’am, Saraswati Ma’am, Pokharia Sir, Vinod Joshi Sir, Dubey Sir, N. Singh Sir, Mishra Sir, Matto Sir, Matto Mam and Dilip Kaul Sir, for bestowing so much love and trust on me
    </br>
    I also thank my  Hermann Gmeiner school friends for still being my amazing support system. The two beautiful years (1997-1999), which I invested in Hermann Gmeiner, provided me enough opportunities to bloom to the fullest. I especially admired the way extracurricular activities were integrated with the academic curriculum, for instance, regular debates, sports carnivals, dance competitions, poem recitals, chorus singing, and drama performances were the part and parcel of everyday life at  Hermann Gmeiner school. In such a short tenure of two years in  Hermann Gmeiner school Bhimtal, I got plenty of opportunities to participate in all of them, isn't it laudable? Right now,
    </br>
    I am the captain of the women’s cricket team of St Johns Aberfeldie Club at Melbourne and  Hermann Gmeiner school did a lot in infusing these leadership skills in me. Professionally, I have done my B.Tech. (Information Technology) from Dehradun Institute of Technology and worked in HCL for six years. Later, I found my passion for teaching and I have recently completed my Master of Teaching from The University of Melbourne. I would be contributing to the Education Sector of Victoria (Australia). I congratulate each one of you for being part of such an amazing institution called  Hermann Gmeiner school. Namaskar! Neelima Bhatt
    `,

    image: juli,
  },
  {
    id: 5,
    title: "Dr. KONTHOUJAM SHAPHABA",
    for: "alumni",
    occupation: "Doctor",
    description: `HERMANN GMEINER SCHOOL, BHIMTAL, residing atop a hill, with the Bhimtal lake beneath. Switzerland could not be more breathtaking. It was my 1st and everlasting impression I had when I landed up on 13th July late night 2 decades ago. I imagine what I would have become, had I not been to this awesome place. From a simpleton, coming from more than 3000 kms, not even knowing how to complete even a single sentence of HINDI.  Hermann Gmeiner school with all my dear respected teachers, Friends gave me the confidence and love and made me the NEHRU house Captain & the School magazine Editor.
    </br>
    It gave a new dimension to my personality. Somehow landing me at TRICHUR, KERALA, and AIIMS, NEW DELHI for my Medical studies. KD sir, now esteemed principal, the epitome of punctuality. Still ringing in my ears, his early morning wake up call at our hostel window, “UTTHO BACCHE” till now. Making sure I visit  Hermann Gmeiner school every year whenever I am in Delhi. Thanks,  Hermann Gmeiner school for moulding me into the human I am now. For my ever caring friends and teachers. I owe you a lot. I AM JUST A TEARDROP IN THE VAST OCEAN OF KNOWLEDGE.
    `,
    image: namrata,
  },
  {
    id: 5,
    title: "Arun Kumar",
    for: "alumni",
    occupation: "Indian Army",
    description: `I am presently a Lt Col and have been serving in the Indian army since 2007 after my initial military training at IMA Dehradun. Today when I look back at the 4 years I spent at  Hermann Gmeiner school Bhimtal, I find myself fortunate enough to have studied in such an environment. I was lucky enough to have been surrounded by teachers and fellow students from whom I learned a lot. At  Hermann Gmeiner school, Bhimtal has been a great contributor in making me what I am. By attending  Hermann Gmeiner school Bhimtal you not only join a school but a family.`,
    image: rakesh,
  },
  {
    id: 5,
    title: "Rohit Sah",
    for: "alumni",
    occupation:
      "country director for India for a respected Middle Eastern advertising firm ",
    description: `In Hermann Gmeiner Bhimtal, the 1994–1995 batch was my class. My two years in the institution, in my opinion, significantly enhanced my personality. The school gave me lots of chances to hone my creative skills through extracurricular activities, physical education lessons, and sports. The school's biggest impact on my life was instilling discipline.
I presently serve as the country director for India for a respected Middle Eastern advertising firm with its headquarters in Dubai. Prior to this, I had top positions with several international advertising firms with headquarters in the UK, Germany, and Africa.
All my teachers at HGS have my sincere thanks, but the now principal Kaladhar Singh  Sir, also referred to as KD Sir, deserves praise for fostering a sense of discipline in me.
I have happy memories of him being in the PT sessions in the morning and participating in lake rounds in our PE lessons.
My life will always hold a particular place for Hermann Gmeiner.`,
    image: Rohit,
  },
  {
    id: 5,
    title: "Mr.Ritesh Padhalni",
    for: "alumni",
    occupation: "Senior General Manager-Marketing at Network18",
    description: `Though it was more than a quarter-century ago, I clearly remember the day I joined Hermann Gmeiner School in class X. There were a lot of apprehensions surmounted by the uncertainty of joining a new school in a Board examination class. Further, the academic year was marred by agitations for a separate Uttarakhand state. But here I was and what I experienced in the next 3 years as an exemplary institution with high standard academic practices, so rare in that century and in that part of the world, aimed at all-round development and immaculate grooming of its students. Soon after joining the school, I was picked by KD Sir for a key role in a Hindi play for the school’s annual function.
    </br>
    The incident taught me that everyone shall get a fair chance irrespective of whether they are new to an environment. Another lesson that I learned during this time was to stand for one’s own. I, unfortunately, got Chicken Pox as the rehearsals were going on. And the school assigned a special vehicle to ferry me to Bhimtal from my home at Bhowali and then back after the sessions
    </br>
    One can surely read/ watch about such values but it is only through the first-hand experience that you imbibe these. The school left no stone unturned in conducting special sessions for Board Exam classes during the long bandh & chakka jams as part of the separate state agitation. It was ensured that no student suffers in this critical phase of their academic life because of the incomplete syllabus. Our Principal at the time – Matto Sir and all the teachers worked really hard to ascertain that the school also gets known for extra-curricular activities along with the academics.
    </br>
    From the football tournament at Nainital to enumerable inter-school debates & declamation competitions, there was hardly any event happening in the region that did not see us participating and winning laurels. When I look back at those years, I realise that all the teachers, lab staff, admin team did teach us something or the other that comes in handy in sailing through life now.
    </br>
    And to the current crop in the school, I can assure you, that you are in one of the safest and most artisan hands of KD Sir. All of you have a bright future ahead. I have seen so many posts from defence personnel here. I too was selected for National Defence Academy (NDA) and underwent training there for a year but, as fate will have it, was boarded out because of an injury. However, during that tough phase, the never-say-die spirit instilled by the school helped me come out of it and build an alternative career somewhere else.
    </br>
    Today I have more than 17 years of experience working with some of the top media brands of the country – Hindustan Times, Radio City, The Indian Express, and Network18. I am currently responsible for the brand marketing of Hindi News Cluster of Network18. The school taught – it is not over, till it is all over – and today my comeback from that painful phase is attributed to the mental & emotional strength that I gained here. I can proudly say today that it was perhaps one of the best decisions of my life to get enrolled at  Hermann Gmeiner school. I have learned so much here and have so many fond memories of the school, teachers, classmates, seniors and juniors alike that if I sit to write it all, I would end up authoring a memoir – an assured bestseller. And I am sure that each one of us,  Hermann Gmeiner school alumni, share the same thought. Thanks to the alma mater & best wishes to each one of us.
    `,
    image: shwetank,
  },
];
export const sports_data = [
  {
    id: 1,
    title: "Sports",
    description: `Having an active lifestyle is key to living a healthy and fit life. To ensure that our students are not only just engrossed in studies, to promote a spirit of sportsmanship and camaraderie, we encourage the students to participate in regularly held intra and inter school events within and outside of the Hermann Gmeiner School community. Taekwondo, Football, Badminton, Lawn Tennis, Table Tennis, Cricket, Chess and Yoga`,
  },
];

export const faculty_data = [
  {
    title: "Faculty & Staff",
    description: `Hermann Gmeiner School Varanasi has a very experienced, knowledgeable, 
    well-qualified and dedicated Staff, so much so that they are conscious of their 
    responsibility of being the most important block in the foundation of the Nation 
    as well as the Society.`,
    description_image: [faculty_1, faculty_2],

    custom_class: ["faculty-img"],
    extra_description: [
      `Their self-discipline is an example in itself, which always drives them to work as 
    a well-knit team to make all possible endeavour together for the holistic growth 
    of students as well the institution. They are enthusiastic, dedicated, 
    conscientious and highly motivated to adapt to new innovative ideas and well 
    versed with modern education systems.`,
      `The valuable contribution of all the Faculty members is well manifested in the 
    spectacular achievements of our students in the scholastic as well as co-
    scholastic discipline.`,
      `The Teachers grouped under various subject-wise Faculties are put under the 
    guidance of the most experienced Faculty heads. All of them always put their 
    heads together to devise improvised methodology to deliver their best to 
    facilitate the young evolving minds. This significantly contributes in promoting 
    the problem solving skills of students to enable them to grow as useful citizens.`,
    ],
  },
];

export const events_data = [
  {
    title: "Welcoming our guests of honor",
    description: `We feel honored to welcome, Mrs. Sudha Shastri , Vice President, SOS Children’s Villages of India & Mrs. Debaroti Bose , Head Education & Child Development (Head ECD) for the visit to our school.  Thanks for your visit gives the school a new recognition every time you visit us.
    We are very thankful for giving us your valuable time. `,
    description_image: [honor],
  },
  {
    title: "Annual Day Celebrations",
    description: `Annual Day forms an integral part of our school activities. It is an occasion of celebration, felicitation, feast, and festivity when students present not only their wonderful performances but also receive honours for their curricular and co-curricular achievements.
    Saraswati Vandana, a student orchestra, and various dances are performed at the event. Annual Day is an important element of our school's calendar. It is a time of celebration, felicitation, feasting, and festivity when students not only present their outstanding performances but also earn awards for their academic and extracurricular achievements. For all of the school's victors and achievers, the Annual Prize Distribution function was a source of pride and honour. Prizes were given to students in both academic and co-curricular fields.
    `,
    description_image: [annual_1],
  },
  {
    title: `Basant Panchami`,
    description: `Our School celebrated Basant Panchami with full zeal and enthusiasm. Our Chief Guest Mr. Dhruv Rautela Our Proud Successful Alumni and Co-Convenor Media BJP Uttarakhand shared the time he spent in the school. Our Principal Sir thanked everyone for putting up a great show.`,
    description_image: [basant_1],
  },
  {
    title: `Watermelon Day`,
    description: `We celebrated ‘Watermelon Day’ for Classes  Nur to I, to enhance the children’s knowledge about it. They communicated the health benefits and were made familiar with the extended vocabulary around the theme. In addition, hygienic ways to consume it were inculcated. It was also quite a sensory experience as they explored the texture, colour, seeds, and taste of the fruit.`,
    description_image: [watermelon],
  },
  {
    title: "Hindi Diwas",
    description: `To commemorate and celebrate the popularity of Hindi throughout the nation, Hindi Diwas is celebrated on the 14th of September each year. On account of this, Our School organized a “Kavi Sammelan”. By the students.
    To Grace, the occasion we had with us honorable Ms. DebarotiBose Ma’am (Head- Education &Child Development SOSChildren’s Villages of India). Addressing the gathering, honorable Ms. D. Bose Ma’am (Head-Education & Child Development SOS Children’s Villages of India). Enlightened the students with various insights into the scope, development, and popularity of Hindi. She also informed the students about the job opportunities in the field of Hindi`,
    description_image: [hindi],
  },
  {
    title: "Teachers Day",
    description: `  To commemorate the birth of the second President of India Dr. Sarvepalli Radhakrishnan, who was a philosopher and a great teacher, the 5th of September every year is celebrated as Teacher’s Day. His contribution to Indian education is undeniable.
    The Teacher’s Day was organized in our school with incredible gusto and zeal by the students. Teachers play a major role in making their students' responsible citizens of tomorrow and good human beings. Hence to express their gratitude, a wide range of activities were organized on this occasion`,
    description_image: [teacherDay],
  },
  {
    title: `Geography Day`,
    description: `As per tradition, Our School takes pride to organize its Geography Exhibition on Saturday, 27th of August, 2022. The main objective is to inculcate research work in senior, Junior, and Primary school students through various projects. The information collected was exhibited in the form of charts. Our Chief Guest Mr. Lalit Prasad Sah Principal Kendriya Vidyalaya Bhimtal graced the occasion. `,
    description_image: [geography],
  },
  {
    title: "Mahatma Gandhi Jayanti",
    description: `The staff and students celebrated the birthday of Mahatma Gandhi ji and Lal Bahadur Shastri Ji. Floral tributes were paid to the two leaders on the occasion. Addressing the students, Prof. Kavita Pandey Our Chief Guest exhorted the students to dedicate themselves to the ideals of Mahatma Gandhi and Lal Bahadur Shastri and to inculcate self-discipline which will help us eradicate the menace of violence in the society. The school principal Mr. K.D.Singh also addressed the students and highlighted the importance of practicing non-violence`,
    description_image: [gandhi],
  },
  {
    title: "Diwali celebration",
    description: `Diwali celebrates the victory of Good over Evil, Light over Darkness, and Bliss over Ignorance.
    Our Primary Wing Celebrated Diwali, to make this occasion more sparkling and exciting parents were invited. Some glimpses from the celebration.`,
    description_image: [diwali],
  },
  {
    title: "PTM",
    description: ` PTMs were held Today respectively. All concerned educators met the parents along with their wards. All educators gave each parent individual feedback about their ward's academic performance. Parents also shared their concerns and suggestions.
    All educators requested the parents to work closely with the educators for the holistic improvement of the students.`,
    description_image: [ptm],
  },
  {
    title: "Children's Day Celebration ",
    description: ` Children's Day was celebrated with full zeal. The school has organised sports meet.
    The programme began with the welcome addressed. Teachers and students gathered on the ground for the opening ceremony of the sports meet. shared the significance of Sports Day. Inauguration of sports meet was done by Our Chief Guest Mr. Vimal Kumar Mishra SHO Bhimtal and Mr K.D.Singh . The meet began with the morning prayer.
    These games were enjoyed by both the teachers and students. Everyone participated with great enthusiasm.`,
    description_image: [childrensDay],
  },

  {
    title: "National Unity Day",
    description: `Remembering Sardar Vallabhbhai Patel on his Birth Anniversary.
    Our school celebrated the birth anniversary of Sardar Vallabhbhai Patel as National Unity Day on 31 Oct 2022.
    To begin with, the National Unity Day pledge was administered to all by the Principal. The students and the staff pledged to uphold the unity and security of the nation. To mark the occasion, a Cleanliness rally was organized by the NCC Cadets, and a Special Assembly was conducted.`,
    description_image: [unityDay],
  },
  {
    title: "Abacus and Vedic Mathematics Workshop",
    description: ` The students and the parents participated in the workshop conducted by the experts of Jigsaw Abacus Wizkid Ms. Sonia Uppal, Mr. Yash Sharma, and Ms. Mansi. The workshop was organized to sharpen the Arithmetical skills of the children. The organizers informed that the students preparing for Various Tests and other competitive examinations will be benefited from this Workshop. The staff and the parents highly appreciated the tricks.`,
    description_image: [abacus],
  },
  {
    title: "Sardar Vallabhbhai Patel Jayanti",
    description: `- Our School honored Rohit Singh Rawat, a talented alumnus who made us feel very proud.
    Rohit Singh Rawat addressed the Parliament in the presence of Our Hon’ble Prime Minister Mr. Narendra Modi Ji, Lok Sabha Speaker Mr. Om Birla, and other Ministers, on 31 October 2022 on the birth anniversary of Sardar Vallabhbhai Patel.`,
    description_image: [svp],
  },

  {
    title: "Spic Macay Show",
    description: `Spic Macay Show was held exclusively for school children. Students were given a remarkable opportunity to see and learn from various maestros and renowned artistic geniuses, an experience that is both intense and inspiring.
    Members of Spic Macay from Madhya Pradesh state and Malwa region mesmerized the students by presenting “Kabir Vani, Meera Bai Ke Bhajan”. Students were informed about the subject of music by Speak Macay.`,
    description_image: [spicMiccay],
  },

  {
    title: "Graded School Science Exhibition",
    description: `There are no dreams too large, no innovation unimaginable, and no frontiers beyond the reach of the students of Our Graded School. The students showcased their Science Exhibition with full zeal and confidence. Dr. Rakesh Pandey Associate Professor and Discipline Head of Accountability at the Tasmanian School of Business and Economics and Dr. Mansi a senior Lecturer (Accounting) at the Tasmanian School of Business and Economics were the chief guest of the day.`,
    description_image: [ScienceExhabition],
  },
  {
    title: "Veer Bal Diwas",
    description: `On 26th December 2022, the School gathered to commemorate  ‘Veer Bal Diwas’ to pay homage to the great valour and supreme sacrifice of Sahibzada Zorawar Singh ji and Sahibzada Fateh Singh ji, the younger sons of the tenth Guru of Sikhs, Guru Gobind Singh ji. Zorawar Singh ji and Sahibzada Fateh Singh ji,  are among the most hallowed martyrs in Sikhism. They had sacrificed their lives in their quest for justice.
    `,
    description_image: [baaldiwas],
  },
  {
    title: "Christmas Day Celebration",
    description: `School celebrated Christmas in all its joy and happiness. Christmas brings cheer and love and we celebrate it with the same passion, spreading the message of love and joy among our children. Our Primary Angeles and Santa danced to joyful tunes. Followed by a Christmas skit and a Kumaoni Folk Song. There were carols presented by our Students & teachers. The Chief Guest for the day was Dr. P.K. Pandey Director of Fisheries Bhimtal. The parents enjoyed the students' performances and appreciated the teachers' efforts in showcasing the mesmerizing performances. Christmas cake was distributed to the students.`,
    description_image: [christmas],
  },
  {
    title: "Republic Day Celebration ",
    description: `The diversity of our nation is one of its major strengths. This Republic Day, commit to pooling our diverse experience for the growth of India. 
    `,
    description_image: [republic],
  },
  {
    title: " Spic Macay Musical-Conversation Between The Body And The Soul",
    description: `The School, under the aegis of SPIC MACAY, hosted a delightful performance by celebrated artists. The program started with the traditional lighting of the lamp and a warm welcome of the artists from Mysore. The students enjoyed the musical environment of Violin, Mridang, and Morsing performed by Dr. Manjunath, Mr. Sumanth, Mr. Anirudha .S.Bhat, and Mr. T.A. Ramnaujan.
    This initiative makes education more holistic and meaningful as it highlights all that is abstract, subtle, inspiring, and mystical in the world, teaching one to look within oneself. 
    `,
    description_image: [SpicMacayMusical2023],
  },
  {
    title: "World Earth Day",
    description: `The World Earth Day Celebration was organized by the School with the aim of raising awareness among children and inspiring them to become dedicated advocates for environmental conservation. An exclusive assembly took place, providing a platform for students to express their perspectives on the importance of preserving Earth and proposing actionable measures to safeguard our planet. Additionally, informative hand-outs were distributed, highlighting the significance of our planet and offering guidelines for maintaining a clean and sustainable environment. Recognizing that our children are the key to our future, it is imperative for each one of us to actively contribute to the environment. By doing so, we invest in a brighter future and strive to create a better world for ourselves and for future generations.
    `,
    description_image: [WorldEarthDay],
  },
  {
    title: "University Connect Career Fair",
    description: `The University Connect Career Fair took place at St. Joseph's College in Nainital. It provided a valuable opportunity for our students to engage with esteemed representatives and faculty members from renowned national and international universities. During the fair, our students actively participated in discussions, enabling the experts to gain insights into their interests and aspirations. This exchange of ideas ensured that the students; perspectives were considered, and relevant suggestions were provided to facilitate their decision-making process. 
    `,
    description_image: [UniversityConnectCareerFair],
  },
  {
    title: "Dr. Hermann Gmeiner Death Anniversary ",
    description: `(23 rd June 1919 – 26 th April 1986) On the occasion of Dr. Hermann Gmeiner's Death Anniversary, our students gathered to commemorate the life and legacy of this remarkable individual. To express their love and respect for Dr. Gmeiner, our students organized a special Morning assembly. The atmosphere was filled with reverence as the students recited heartfelt poems and delivered speeches, highlighting the achievements and values embodied by Dr. Gmeiner. The recitations and speeches served as a testament to the profound impact Dr. Gmeiner had on the lives of countless individuals, inspiring them to carry forward his legacy of compassion and service. 
    `,
    description_image: [gmeinerDeathAnniversary],
  },
  {
    title: "Mother’s Day Celebration",
    description: `Mother's Day, a day that acknowledges the
    profound significance of mothers, was joyously celebrated with great
    enthusiasm and merriment. With the intention of nurturing this special bond,
    our school organized a dedicated celebration for Mother's Day. We were
    honored to have Mrs. Neema Bisht, Former Chairman of Nagar Palika Bhowali,
    as our esteemed Chief Guest. Her presence brought inspiration and wisdom to
    the event. During her address, Mrs. Neema Bisht encouraged the children to
    cultivate virtuous actions and embrace ethical values that bring happiness and
    contentment to their parents. The event was filled with heartfelt expressions
    of love, as students and teachers conveyed their appreciation through various
    activities, performances, and heartfelt gestures. 
    `,
    description_image: [mothersDay],
  },
  {
    title: "Discovering Happiness Within",
    description: `
    On May 17, 2023, Hermann Gmeiner School organized a special session titled &quot;Discovering
    Happiness Within&quot; for its boarding students. The session was conducted by Mrs. Annu Vittal,
    a highly accomplished Canadian entrepreneur, visual artist, poet, author, and life coach. As
    the CEO of AVC INC, Mrs. Vittal is dedicated to empowering individuals to create, connect,
    and celebrate.
    The primary objective of the session was to address several important aspects:
    1. Understanding the challenges and issues affecting the students.
    2. Providing guidance and support for achieving positive mental health.
    3. Developing strategies to overcome difficulties.
    4. Inspiring students to pursue successful endeavors and find happiness in their lives.
    Mrs. Annu Vittal motivated the students by sharing relevant examples and suggesting
    alternative approaches to enhance their mental resilience. Her insights aimed to strengthen
    their problem-solving abilities and empower them to tackle their own challenges. Overall, the
    session aimed to create a nurturing and supportive environment for the boarding students,
    equipping them with valuable tools to navigate life's obstacles and strive towards personal
    growth and happiness.
    `,
    description_image: [DiscoveringHappiness],
  },
];
